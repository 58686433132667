$white: #ffffff;
$black: #000000;
$black2: #333333;
$black3: #222222;
$black4: #1D2131;
$active: #2A5586;
$active-hover: #486b86;
$blue: #4FCDFF;
$blue2: #1F497A;
$blue3: #6E97C6;
$bord: #EDEFF2;
$bord2: #DBDBDB;
$bord3: #3D404D;
$bg: #F7F7F7;
$bg2: #F2F2F2;
$color: #828282;
$gray: #A1A4B3;
$gray2: #898F97;
$gray3: #E5E5E5;
$red: #F32727;
$red2: #FF0000;
$orange: #F57C00;

$font-family: 'MuseoSansCyrl', sans-serif;
