@charset "UTF-8";
@font-face {
  font-family: 'MuseoSansCyrl';
  src: url("../fonts/MuseoSansCyrl-300.eot");
  src: url("../fonts/MuseoSansCyrl-300.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-300.woff") format("woff"), url("../fonts/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'MuseoSansCyrl';
  src: url("../fonts/MuseoSansCyrl-500.eot");
  src: url("../fonts/MuseoSansCyrl-500.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-500.woff") format("woff"), url("../fonts/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'MuseoSansCyrl';
  src: url("../fonts/MuseoSansCyrl-700.eot");
  src: url("../fonts/MuseoSansCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-700.woff") format("woff"), url("../fonts/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

body {
  min-width: 320px;
  padding-top: 80px;
  font-family: "MuseoSansCyrl", sans-serif;
  font-weight: 500; }
  body.overfl {
    overflow: hidden;
    height: 100vh; }

.start-page {
  padding: 30px; }
  .start-page-logo {
    display: block;
    margin-bottom: 30px; }
  .start-page ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px; }
    .start-page ul li {
      padding: 5px 0; }
      .start-page ul li b {
        font-size: 20px; }
      .start-page ul li a {
        font-size: 16px;
        line-height: 140%;
        color: #000000; }
        .start-page ul li a:hover {
          color: #2A5586; }

div:focus,
span:focus,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus,
.button:focus {
  outline: none; }

ul {
  margin-bottom: 0; }
  ul li {
    list-style: none; }

a {
  cursor: pointer;
  transition: 0.3s; }
  a:hover {
    text-decoration: none; }

.container {
  max-width: 100%; }
  .header .container,
  .footer .container {
    max-width: 100%; }
    @media screen and (min-width: 1420px) {
      .header .container,
      .footer .container {
        max-width: 1390px; } }
  @media screen and (min-width: 1360px) {
    .form .container {
      max-width: 1330px; } }
  @media screen and (min-width: 1230px) {
    .container {
      max-width: 1200px; } }

h1 {
  margin-bottom: 40px; }

h2, h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;
  color: #333333; }

h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%; }

.title-main {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .title-main h2 {
    margin-bottom: 0; }
  .title-main__link {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2A5586; }
    .title-main__link:hover {
      color: #486b86; }

.button {
  display: inline-block;
  box-shadow: none !important;
  cursor: pointer;
  border: none;
  transition: 0.3s; }
  .button.mob {
    display: none; }
  .button-group .button {
    display: inline-block;
    margin-right: 20px; }
  .button-group.mob {
    display: none; }
  .button-center {
    margin: 10px 0;
    text-align: center; }
    .button-center.mob {
      display: none; }
    .button-center .button {
      margin: 0 10px; }
  .button-primary {
    display: inline-flex;
    align-items: center;
    padding: 8px 20px;
    border-radius: 8px;
    background: #2A5586;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #ffffff;
    border: 2px solid #2A5586; }
    .header .button-primary {
      font-size: 14px; }
    .button-primary svg {
      width: 20px;
      height: 20px;
      margin-right: 10px; }
      .button-primary svg use {
        color: #ffffff; }
    .button-primary:hover {
      background: #4FCDFF;
      border-color: #4FCDFF;
      color: #2A5586; }
      .button-primary:hover svg use {
        color: #2A5586; }
  .button-secondary {
    padding: 15px 20px;
    border-radius: 8px;
    background: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #2A5586;
    border: 2px solid #ffffff; }
    .button-secondary:hover {
      background: transparent;
      border-color: #4FCDFF;
      color: #4FCDFF; }
  .button-bord {
    padding: 15px 20px;
    border-radius: 8px;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #ffffff;
    border: 2px solid #ffffff; }
    .button-bord:hover {
      background: #4FCDFF;
      border-color: #4FCDFF;
      color: #2A5586; }
  .button-bord2 {
    padding: 15px 20px;
    border-radius: 8px;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #2A5586;
    border: 2px solid #2A5586; }
    .button-bord2:hover {
      background: #4FCDFF;
      border-color: #4FCDFF;
      color: #2A5586; }
  .button-big {
    padding: 15px 40px; }

.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); }
  .header__toggler {
    display: none; }
  .header__mob {
    display: none; }
  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__logo {
    display: block; }
  .header__close {
    display: none; }
  .header__menu {
    height: 80px;
    display: flex;
    align-items: center; }
    .header__menu ul {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .header__menu ul li {
        height: 100%;
        padding: 0 16px; }
        .header__menu ul li a {
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: #333333;
          text-transform: uppercase; }
          .header__menu ul li a:after {
            content: '';
            width: 100%;
            height: 4px;
            display: block;
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #2A5586; }
          .header__menu ul li a:hover {
            color: #2A5586; }
        @media screen and (min-width: 991px) {
          .header__menu ul li:hover > a {
            color: #2A5586; }
            .header__menu ul li:hover > a:after {
              opacity: 1; }
          .header__menu ul li:hover > ul {
            height: auto;
            display: block; } }
        .header__menu ul li > ul {
          width: 920px;
          padding: 57px 55px;
          display: none;
          position: absolute;
          top: 80px;
          left: -60px;
          webkit-column-count: 3;
          column-count: 3;
          background: #1D2131; }
          .header__menu ul li > ul li {
            height: auto;
            padding: 0 10px 0 0;
            margin-bottom: 20px; }
            .header__menu ul li > ul li a {
              height: auto;
              text-transform: none;
              font-size: 16px;
              line-height: 16px;
              font-weight: 400;
              color: #ffffff; }
              .header__menu ul li > ul li a:after {
                display: none; }
              .header__menu ul li > ul li a:hover {
                color: #4FCDFF; }
  .header__info {
    display: flex;
    align-items: center; }
  .header__contacts {
    text-align: right;
    margin-right: 65px; }
  .header__tel {
    display: block;
    font-size: 18px;
    line-height: 100%;
    color: #222222;
    font-weight: 500; }
    .header__tel:hover {
      color: #2A5586; }
  .header__call {
    font-size: 13px;
    line-height: 14px;
    color: #2A5586; }
    .header__call:hover {
      color: #486b86; }

.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .banner__slider {
    position: relative; }
    .banner__slider .progressBarContainer {
      display: flex;
      align-items: center;
      width: 100%;
      position: absolute;
      bottom: 90px;
      left: 0; }
      .banner__slider .progressBarContainer__title {
        position: absolute;
        bottom: -40px;
        font-weight: 300;
        font-size: 15px;
        line-height: 170%;
        color: #ffffff; }
      .banner__slider .progressBarContainer div {
        display: block;
        width: 100%;
        padding: 0;
        cursor: pointer;
        margin-right: 20px;
        color: #ffffff;
        position: relative; }
        .banner__slider .progressBarContainer div .progressBar {
          width: 100%;
          height: 3px;
          background-color: rgba(255, 255, 255, 0.2);
          display: block;
          border-radius: 2px; }
          .banner__slider .progressBarContainer div .progressBar .inProgress {
            background-color: white;
            width: 0%;
            height: 3px;
            position: absolute;
            top: 25px;
            border-radius: 2px; }
    .banner__slider-item {
      padding: 100px 0 110px; }
    .banner__slider-text {
      padding-left: 5px; }
      .banner__slider-text p {
        margin-bottom: 40px;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: #ffffff; }
    .banner__slider-categor {
      margin-bottom: 13px;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #4FCDFF;
      text-transform: uppercase; }
    .banner__slider-title {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
      color: #ffffff; }
  .banner-main {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .banner-main h1, .banner-main h2 {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 64px;
      line-height: 110%;
      color: #ffffff; }
      .banner-main h1 + p, .banner-main h2 + p {
        margin-top: -20px; }
    .banner-main__inner {
      padding: 80px 0 40px; }
    .banner-main__categor {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #4FCDFF; }
    .banner-main__text p {
      margin-bottom: 40px;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      color: #ffffff; }

.services {
  padding: 80px 0;
  background: #F7F7F7; }
  .services-page {
    padding: 0 0 90px; }
  .services .col-lg-3 {
    margin-bottom: 30px; }
  .services__item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    padding: 20px 50px 50px 20px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #EDEFF2;
    background-color: #ffffff;
    background-size: 140px 140px;
    background-repeat: no-repeat;
    background-position: 100% 85%;
    overflow: hidden;
    transition: 0.3s;
    /*&:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s;
      }*/ }
    .services__item-im-hov {
      opacity: 0;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.3s; }
    .services__item:hover {
      /*&.services__item-icon1 {
          &:after {
            background-image: url("../images/serv-icon-hov1.jpg");
          }
        }
        &.services__item-icon2 {
          &:after {
            background-image: url("../images/serv-icon-hov2.jpg");
          }
        }
        &.services__item-icon3 {
          &:after {
            background-image: url("../images/serv-icon-hov3.jpg");
          }
        }
        &.services__item-icon4 {
          &:after {
            background-image: url("../images/serv-icon-hov4.jpg");
          }
        }
        &.services__item-icon5 {
          &:after {
            background-image: url("../images/serv-icon-hov5.jpg");
          }
        }
        &.services__item-icon6 {
          &:after {
            background-image: url("../images/serv-icon-hov6.jpg");
          }
        }
        &.services__item-icon7 {
          &:after {
            background-image: url("../images/serv-icon-hov7.jpg");
          }
        }
        &.services__item-icon8 {
          &:after {
            background-image: url("../images/serv-icon-hov8.jpg");
          }
        }
        &.services__item-icon9 {
          &:after {
            background-image: url("../images/serv-icon-hov9.jpg");
          }
        }
        &.services__item-icon10 {
          &:after {
            background-image: url("../images/serv-icon-hov10.jpg");
          }
        }
        &.services__item-icon11 {
          &:after {
            background-image: url("../images/serv-icon-hov11.jpg");
          }
        }
        &.services__item-icon12 {
          &:after {
            background-image: url("../images/serv-icon-hov12.jpg");
          }
        }*/ }
      .services__item:hover:after {
        opacity: 1; }
      .services__item:hover .services__item-im-hov {
        opacity: 1;
        z-index: 1; }
      .services__item:hover .services__item-title,
      .services__item:hover svg use {
        color: #ffffff; }
      .services__item:hover p {
        display: none; }
      .services__item:hover svg {
        display: block;
        opacity: 0;
        transition: 0.3s; }
        .services__item:hover svg.hov {
          display: block;
          width: 61px !important;
          height: 6px !important;
          opacity: 1; }
    .services__item a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .services__item-tag {
      padding: 8px 18px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      border-radius: 0 8px 0 8px; }
      .services__item-tag-profitable {
        color: #ffffff;
        background: #F32727; }
      .services__item-tag-new {
        color: #2A5586;
        background: #4FCDFF; }
      .services__item-tag-actual {
        color: #ffffff;
        background: #F57C00; }
      .services__item-tag-soon {
        color: #ffffff;
        background: #2A5586; }
    .services__item-img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: none; }
      .services__item-img:hover {
        background-color: transparent; }
      .services__item-img p {
        display: none; }
      .services__item-img svg {
        width: 61px !important;
        height: 6px !important;
        position: relative;
        z-index: 2; }
        .services__item-img svg.hov {
          display: block;
          opacity: 0; }
        .services__item-img svg use {
          color: #ffffff !important; }
    .services__item-title {
      min-height: 55px;
      margin-bottom: 15px;
      position: relative;
      z-index: 2;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #333333;
      transition: 0.3s; }
      .services__item-img .services__item-title {
        color: #ffffff; }
    .services__item p {
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      transition: 0.3s; }
    .services__item svg {
      width: 21px;
      height: 6px;
      position: absolute;
      bottom: 30px;
      left: 20px;
      z-index: 2;
      transition: 0.3s; }
      .services__item svg use {
        color: #000000; }
  .services-main .col-sm-12 {
    display: none; }
    .services-main .col-sm-12:nth-of-type(1), .services-main .col-sm-12:nth-of-type(2), .services-main .col-sm-12:nth-of-type(3), .services-main .col-sm-12:nth-of-type(4), .services-main .col-sm-12:nth-of-type(5), .services-main .col-sm-12:nth-of-type(6), .services-main .col-sm-12:nth-of-type(7), .services-main .col-sm-12:nth-of-type(8) {
      display: block; }
    .open .services-main .col-sm-12 {
      display: block; }
  .services-main.open .col-sm-12 {
    display: block; }
  .services-main.open .button-primary {
    color: transparent;
    position: relative; }
    .services-main.open .button-primary:after {
      content: 'Скрыть';
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.04em;
      color: #ffffff; }

.about {
  padding: 110px 0 40px; }
  .about-bg {
    background: #F7F7F7; }
  .about__text {
    padding-right: 30px; }
    .about__text h2 {
      margin-bottom: 30px; }
    .about__text p {
      font-weight: 300;
      font-size: 18px;
      line-height: 170%;
      color: #828282; }
  .about__item {
    margin-bottom: 60px; }
    .about__item .button {
      margin: 30px 0; }
    .about__item-title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      color: #222222; }
    .about__item p {
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      color: #222222; }

.case {
  padding: 100px 0;
  background: #F7F7F7; }
  .case .col-lg-6 {
    margin-bottom: 30px; }
  .case-page {
    padding: 0;
    position: relative;
    background: #ffffff;
    overflow: hidden; }
  .case-description {
    position: relative; }
    .case-description:after {
      content: '';
      width: 30%;
      height: 100%;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background: #F7F7F7; }
    .case-description__text-inner {
      padding-bottom: 50px; }
      .case-description__text-inner h2 {
        margin-bottom: 40px; }
      .case-description__text-inner h3 {
        margin-bottom: 20px; }
      .case-description__text-inner p {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000; }
        .case-description__text-inner p + h3 {
          margin-top: 30px; }
    .case-description__list {
      height: 100%;
      padding: 100px 55px;
      position: relative;
      z-index: 3;
      background: #F7F7F7; }
      .case-description__list-item {
        display: flex;
        align-items: center;
        margin-bottom: 40px; }
      .case-description__list-img {
        flex: 0 0 116px;
        max-width: 116px; }
      .case-description__list-tx {
        width: calc(100% - 116px);
        padding-left: 15px; }
        .case-description__list-tx p {
          margin-bottom: 0;
          font-weight: 300;
          font-size: 16px;
          line-height: 140%;
          color: #222222; }
      .case-description__list-title {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        color: #222222; }
  .case__item {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #DBDBDB;
    background-color: #ffffff; }
    .case__item a {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0; }
    .case__item:hover .case__item-info:after {
      opacity: 1; }
    .case__item:hover .case__item-img {
      transform: scale(1.1); }
    .case__item:hover .case__item-title {
      color: #2A5586; }
    .case__item:hover svg {
      width: 61px;
      height: 6px;
      transition: 0.3s; }
      .case__item:hover svg.ar {
        display: block;
        opacity: 0; }
      .case__item:hover svg.ar-hov {
        display: block;
        opacity: 1; }
      .case__item:hover svg use {
        color: #2A5586; }
    .case__item-img {
      height: 258px;
      width: 100%;
      padding: 20px;
      position: relative;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: 0.3s; }
    .case__item-sticker {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2; }
      .case__item-sticker-item {
        margin-bottom: 20px; }
      .case__item-sticker-title {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 18px;
        color: #ffffff; }
      .case__item-sticker-tx {
        font-weight: 300;
        font-size: 18px;
        line-height: 18px;
        color: #ffffff; }
    .case__item-info {
      padding: 20px 20px 60px;
      background: #ffffff;
      position: relative;
      z-index: 2; }
      .case__item-info:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: url("../images/bg-hover2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s; }
      .case__item-info p {
        margin-bottom: 0;
        position: relative;
        z-index: 2;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #222222; }
      .case__item-info svg {
        width: 21px;
        height: 6px;
        position: absolute;
        bottom: 25px;
        left: 20px;
        z-index: 2; }
        .case__item-info svg.ar {
          display: block; }
        .case__item-info svg.ar-hov {
          display: block;
          opacity: 0; }
        .case__item-info svg use {
          color: #333333; }
    .case__item-title {
      margin-bottom: 10px;
      position: relative;
      z-index: 2;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #333333; }
  .case__tabs {
    overflow: hidden; }
    .case__tabs .nav-tabs {
      margin-bottom: 30px;
      border: none; }
      .integration .case__tabs .nav-tabs {
        margin-bottom: 50px; }
    .case__tabs .nav-item {
      margin-right: 7px;
      margin-bottom: 10px; }
    .case__tabs .nav-link {
      padding: 10px 31px;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #333333;
      border-radius: 30px;
      background: #F7F7F7; }
      .integration .case__tabs .nav-link,
      .purpose-tw .case__tabs .nav-link {
        background: #ffffff; }
      .case__tabs .nav-link:hover {
        color: #ffffff;
        background: #2A5586; }
      .case__tabs .nav-link.active {
        color: #ffffff;
        background: #2A5586; }

.connect {
  padding: 85px 0 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .connect h2 {
    margin-bottom: 80px;
    color: #ffffff;
    text-align: center; }
  .connect__inner {
    margin-bottom: 65px;
    display: flex;
    align-items: flex-start;
    justify-content: center; }
  .connect__item {
    padding: 0 25px;
    flex: 0 0 33.333%;
    max-width: 100%;
    position: relative;
    text-align: center; }
    .connect__item:after {
      content: '';
      width: 60%;
      height: 1px;
      display: inline-block;
      position: absolute;
      right: -30%;
      top: 198px;
      background-color: rgba(255, 255, 255, 0.2); }
    .connect__item:last-of-type:after {
      display: none; }
    .connect__item p {
      font-weight: 300;
      font-size: 24px;
      line-height: 120%;
      color: #ffffff; }
    .connect__item-tx {
      font-weight: 300;
      font-size: 17px;
      line-height: 140%;
      color: #6E97C6; }
    .connect__item-img {
      height: 120px;
      margin-bottom: 50px; }
    .connect__item-numb {
      margin-bottom: 20px;
      width: 60px;
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: #ffffff;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      color: #1F497A; }

.news {
  padding: 100px 0; }
  .news .col-lg-4 {
    margin-bottom: 30px; }
  .news-page {
    padding: 0; }
  .news-al {
    padding-bottom: 70px;
    background: #F7F7F7; }
  .news-item__inner {
    display: flex;
    align-items: flex-start;
    padding-bottom: 85px; }
  .news-item__description {
    width: calc(100% - 370px);
    padding-right: 80px; }
    .news-item__description h2 {
      margin: 40px 0 22px;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: #222222; }
    .news-item__description h3 {
      margin: 40px 0 22px;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #222222; }
    .news-item__description img {
      margin: 20px 0; }
    .news-item__description p {
      margin-bottom: 17px;
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: #000000; }
      .policy .news-item__description p {
        font-weight: 400; }
    .news-item__description .table-container {
      padding: 0 15px;
      margin-bottom: 20px;
      border: 1px solid #DBDBDB;
      border-radius: 8px; }
    .news-item__description table {
      width: 100%; }
      .news-item__description table tr th {
        font-weight: 600; }
      .news-item__description table tr td {
        font-weight: 300; }
      .news-item__description table tr th, .news-item__description table tr td {
        padding: 17px 10px;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        border-bottom: 1px solid #DBDBDB; }
      .news-item__description table tr:last-of-type td {
        border: none; }
    .news-item__description ul {
      padding-left: 20px; }
      .news-item__description ul li {
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        list-style: disc; }
        .policy .news-item__description ul li {
          font-weight: 400; }
    .news-item__description ol {
      counter-reset: myCounter;
      padding-left: 0;
      margin-bottom: 35px; }
      .news-item__description ol > li {
        min-height: 40px;
        padding-left: 60px;
        margin-bottom: 20px;
        position: relative;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        list-style: none; }
        .policy .news-item__description ol > li {
          font-weight: 400; }
        .news-item__description ol > li:before {
          counter-increment: myCounter;
          content: counters(myCounter, ".");
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-right: 6px;
          position: absolute;
          top: -5px;
          left: 0;
          background: #F7F7F7;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          border-radius: 100px; }
  .news-item__data {
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 15px;
    line-height: 170%;
    color: #000000; }
  .news-item__info {
    width: 370px;
    padding-top: 40px; }
    .operator .news-item__info {
      padding-top: 0; }
  .news__item {
    height: 100%;
    padding: 30px 30px 80px;
    position: relative;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    background-color: #ffffff; }
    .news-item__info .news__item {
      height: 370px; }
    .news__item .button {
      position: relative;
      z-index: 2; }
    .news__item-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .news__item-img p {
        color: #ffffff !important; }
      .news__item-img .button {
        position: absolute;
        left: 30px;
        bottom: 30px; }
      .news__item-img:hover .button {
        color: #2A5586;
        background: #ffffff; }
      .news__item-img:hover p, .news__item-img:hover .news__item-title {
        color: #000000 !important; }
    .news__item:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: url("../images/news-hover-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s; }
    .news__item:hover {
      border-color: transparent;
      background-image: none !important; }
      .news__item:hover:after {
        opacity: 1; }
      .news__item:hover svg.ar {
        display: block;
        opacity: 0; }
      .news__item:hover svg.ar-hov {
        width: 61px;
        height: 6px;
        display: block;
        opacity: 1; }
    .news__item a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3; }
    .news__item p {
      margin-bottom: 0;
      position: relative;
      z-index: 2;
      color: #222222;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%; }
    .news__item svg {
      width: 21px;
      height: 6px;
      position: absolute;
      bottom: 25px;
      left: 30px;
      z-index: 2;
      transition: 0.3s; }
      .news__item svg.ar {
        display: block; }
      .news__item svg.ar-hov {
        display: block;
        opacity: 0; }
      .news__item svg use {
        color: #000000; }
    .news__item-data {
      margin-bottom: 40px;
      position: relative;
      z-index: 2;
      font-weight: 300;
      font-size: 15px;
      line-height: 170%;
      color: #000000; }
    .news__item-title {
      margin-bottom: 15px;
      position: relative;
      z-index: 2;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #222222; }
      .news__item-img .news__item-title {
        color: #ffffff;
        font-weight: 600;
        font-size: 36px;
        line-height: 100%; }

.form {
  padding: 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .news-item__info .form {
    padding: 45px;
    margin-bottom: 30px;
    background: #F7F7F7;
    border-radius: 8px; }
  .modal-body .form {
    padding: 30px 0 0;
    border-radius: 0;
    background: transparent; }
  .form__inner {
    padding: 75px 60px;
    background: #ffffff; }
  .form__top {
    margin-bottom: 83px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .news-item__info .form__top {
      display: block;
      margin-bottom: 0; }
    .form__top h2 {
      margin-bottom: 20px; }
      .news-item__info .form__top h2 {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%; }
    .form__top-title {
      width: calc(100% - 450px);
      font-weight: 300;
      font-size: 18px;
      line-height: 120%;
      color: #000000; }
      .news-item__info .form__top-title {
        width: 100%;
        padding: 15px 0 22px; }
  .form__bottom {
    display: flex;
    align-items: center;
    margin-top: 60px; }
    .news-item__info .form__bottom,
    .modal-body .form__bottom {
      display: block;
      margin-top: -10px; }
  .form__error input {
    border-color: #FF0000 !important; }
  .form__error span {
    color: #FF0000;
    font-size: 13px;
    line-height: 100%; }
  .form__tx {
    margin-left: 60px;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #000000; }
    .news-item__info .form__tx,
    .modal-body .form__tx {
      margin-left: 0;
      margin-top: 19px;
      color: #828282; }
      .news-item__info .form__tx a,
      .modal-body .form__tx a {
        color: #828282; }
    .form__tx a {
      color: #000000;
      text-decoration: underline; }
      .form__tx a:hover {
        color: #2A5586; }
  .form__cell {
    position: relative; }
    .news-item__info .form__cell,
    .modal-body .form__cell {
      margin-bottom: 33px; }
    .form__cell input {
      padding: 5px 0;
      width: 100%;
      height: 40px;
      margin-bottom: 0;
      position: relative;
      z-index: 1;
      color: #000000;
      font-weight: 300;
      font-size: 24px;
      line-height: 100%;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      border-bottom: 1px solid #000000;
      background-color: transparent; }
      .form__cell input.form__input_effect ~ label, .form__cell input.form__textarea_effect ~ label {
        position: absolute;
        left: 0;
        top: 11px;
        width: 100%;
        transition: 0.3s;
        z-index: 10;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #000000; }
        .form__cell input.form__input_effect ~ label:hover, .form__cell input.form__textarea_effect ~ label:hover {
          cursor: text; }
      .form__cell input.form__input_effect:focus ~ label,
      .form__cell input.form__input_effect.has-content ~ label, .form__cell input.form__textarea_effect:focus ~ label,
      .form__cell input.form__textarea_effect.has-content ~ label {
        bottom: inherit;
        top: -18px;
        transition: 0.3s; }

.footer {
  background: #1D2131; }
  .footer__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 48px 0 115px; }
  .footer__left {
    display: flex;
    align-items: flex-start; }
  .footer__contacts {
    text-align: right; }
    .footer__contacts .button {
      padding: 10px 28px; }
      .footer__contacts .button:hover {
        color: #4FCDFF;
        border-color: #4FCDFF;
        background: transparent; }
    .footer__contacts-tel {
      display: inline-block;
      margin-bottom: 13px;
      font-size: 28px;
      line-height: 100%;
      color: #ffffff; }
      .footer__contacts-tel:hover {
        color: #A1A4B3; }
    .footer__contacts-inf {
      padding: 15px 0 25px;
      font-weight: 300;
      font-size: 18px;
      line-height: 16px;
      color: #ffffff; }
    .footer__contacts-soc {
      margin-bottom: 30px; }
      .footer__contacts-soc li {
        display: inline-block;
        margin-left: 5px; }
        .footer__contacts-soc li a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          border: 1px solid #3D404D; }
          .footer__contacts-soc li a svg {
            width: 18px;
            height: 18px; }
            .footer__contacts-soc li a svg use {
              color: #ffffff; }
          .footer__contacts-soc li a:hover {
            background: #ffffff;
            border-color: #ffffff; }
            .footer__contacts-soc li a:hover svg use {
              color: #1D2131; }
  .footer__menu {
    flex: 0 0 35%;
    padding-right: 20px; }
    .footer__menu-title {
      margin-bottom: 25px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #F7F7F7; }
      .footer__menu-title + .footer__menu-title {
        margin-top: 25px; }
      .footer__menu-title:hover {
        color: #A1A4B3; }
    .footer__menu-btn {
      margin-top: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #4FCDFF;
      cursor: pointer; }
      .footer__menu-btn + ul {
        margin-top: 45px; }
      .open .footer__menu-btn {
        display: none; }
      .footer__menu-btn:hover {
        color: #ffffff; }
    .footer__menu-nn ul li:nth-of-type(n+9) {
      display: none; }
    .footer__menu-fv ul li:nth-of-type(n+6) {
      display: none; }
    .footer__menu ul li {
      margin-bottom: 8px; }
      .footer__menu ul li a {
        font-size: 14px;
        line-height: 16px;
        color: #A1A4B3; }
        .footer__menu ul li a:hover {
          color: #ffffff; }
    .footer__menu ul.open li {
      display: block !important; }
    .footer__menu ul.open + .footer__menu-btn {
      display: none; }
  .footer__bottom {
    padding: 20px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #3D404D; }
    .footer__bottom-left {
      display: flex;
      align-items: center; }
    .footer__bottom-tx {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      color: #A1A4B3; }
    .footer__bottom-link {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      color: #A1A4B3; }
      .footer__bottom-link:hover {
        color: #ffffff; }
    .footer__bottom ul {
      margin-left: 100px; }
      .footer__bottom ul li {
        display: inline-block; }
        .footer__bottom ul li a {
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
          color: #A1A4B3; }
          .footer__bottom ul li a:hover {
            color: #ffffff; }
        .footer__bottom ul li:first-of-type:after {
          content: '|';
          display: inline-block;
          padding-left: 5px;
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
          color: #A1A4B3; }

.breadcrumb {
  padding: 0; }
  .breadcrumb-block {
    padding: 42px 0 40px; }
    .breadcrumb-block .breadcrumb {
      padding: 0;
      margin: 0;
      background: transparent; }
      .breadcrumb-block .breadcrumb-item {
        font-weight: 300;
        font-size: 14px;
        line-height: 170%;
        color: #898F97;
        margin-bottom: 0; }
        .breadcrumb-block .breadcrumb-item:first-of-type:before {
          display: none !important; }
        .https404 .breadcrumb-block .breadcrumb-item {
          color: #ffffff; }
        .breadcrumb-block .breadcrumb-item a {
          font-weight: 300;
          font-size: 14px;
          line-height: 170%;
          color: #898F97; }
          .https404 .breadcrumb-block .breadcrumb-item a {
            color: #ffffff; }
          .breadcrumb-block .breadcrumb-item a:hover {
            color: #2A5586; }
            .https404 .breadcrumb-block .breadcrumb-item a:hover {
              color: #A1A4B3; }
        .breadcrumb-block .breadcrumb-item + .breadcrumb-item:before {
          content: '–';
          padding-right: 8px;
          padding-left: 8px;
          font-weight: 300;
          font-size: 14px;
          line-height: 170%;
          color: #898F97; }
          .https404 .breadcrumb-block .breadcrumb-item + .breadcrumb-item:before {
            color: #ffffff; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination-bl {
    padding: 20px 0 95px; }
  .pagination .page-item {
    margin: 0 5px; }
    .pagination .page-item a, .pagination .page-item span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
      border-radius: 100px !important;
      border: none;
      box-shadow: none !important; }
      .pagination .page-item a:hover, .pagination .page-item span:hover {
        background: #2A5586;
        color: #ffffff; }
    .pagination .page-item span {
      cursor: inherit !important;
      color: #000000;
      background: transparent; }
      .pagination .page-item span:hover {
        color: #000000;
        background: transparent; }
    .pagination .page-item.active {
      background: #2A5586;
      color: #ffffff;
      border-radius: 100px; }
      .pagination .page-item.active a, .pagination .page-item.active span {
        background: #2A5586;
        color: #ffffff; }
    .pagination .page-item.prev, .pagination .page-item.next {
      margin: 0 10px;
      min-height: 40px; }
      .pagination .page-item.prev .page-link, .pagination .page-item.next .page-link {
        width: auto;
        height: auto;
        position: relative;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        background: transparent !important; }
        .pagination .page-item.prev .page-link:hover, .pagination .page-item.next .page-link:hover {
          color: #000000; }
          .pagination .page-item.prev .page-link:hover .arrow, .pagination .page-item.next .page-link:hover .arrow {
            background: #2A5586; }
            .pagination .page-item.prev .page-link:hover .arrow svg use, .pagination .page-item.next .page-link:hover .arrow svg use {
              color: #ffffff; }
      .pagination .page-item.prev .arrow, .pagination .page-item.next .arrow {
        width: 40px;
        height: 40px;
        background: #F7F7F7; }
        .pagination .page-item.prev .arrow svg, .pagination .page-item.next .arrow svg {
          width: 25px;
          height: 25px; }
          .pagination .page-item.prev .arrow svg use, .pagination .page-item.next .arrow svg use {
            color: #000000; }
      .pagination .page-item.prev span, .pagination .page-item.next span {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px; }
    .pagination .page-item.prev {
      margin-right: 50px; }
      .pagination .page-item.prev .page-link {
        padding-left: 55px; }
        .pagination .page-item.prev .page-link .arrow {
          position: absolute;
          left: -29px;
          top: 9px; }
    .pagination .page-item.next {
      margin-left: 50px; }
      .pagination .page-item.next .page-link .arrow {
        margin-left: 15px;
        position: absolute;
        right: -66px;
        top: 9px;
        transform: rotate(180deg); }

.terms ol {
  counter-reset: myCounter;
  padding-left: 0; }
  .terms ol > li {
    padding-left: 0;
    margin-bottom: 60px;
    position: relative;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #000000;
    list-style: none; }
    .terms ol > li:before {
      counter-increment: myCounter;
      content: counters(myCounter, ".") ". ";
      width: auto;
      height: auto;
      display: inline-block;
      margin-right: 6px;
      position: relative;
      top: 0;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #000000;
      background: transparent; }
    .terms ol > li > ol {
      margin-top: 17px;
      padding-left: 0; }
      .terms ol > li > ol > li {
        margin-bottom: 20px;
        padding-left: 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        text-transform: initial; }
        .terms ol > li > ol > li:before {
          counter-increment: myCounter;
          content: counters(myCounter, ".") ". ";
          display: inline-block;
          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          color: #000000; }
        .terms ol > li > ol > li > ol {
          margin-top: 17px;
          padding-left: 0; }
          .terms ol > li > ol > li > ol > li {
            margin-bottom: 20px;
            padding-left: 0;
            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: #000000;
            text-transform: initial; }
            .terms ol > li > ol > li > ol > li:before {
              counter-increment: myCounter;
              content: counters(myCounter, ".") ". ";
              display: inline-block;
              font-weight: 300;
              font-size: 16px;
              line-height: 160%;
              color: #000000; }

.modal {
  padding: 0 15px; }
  .modal-dialog {
    max-width: 370px;
    margin: auto; }
  .modal-content {
    border-radius: 8px;
    border: none; }
    .modal-content .close {
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 1;
      z-index: 3; }
  .modal-body {
    padding: 45px 50px 60px; }
    .modal-body__img {
      margin-bottom: 20px; }
    .modal-body__title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #000000; }
    .modal-body__tx {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: #000000; }

.https404 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .https404__img {
    margin-bottom: 40px; }
  .https404__inner {
    margin: auto;
    text-align: center;
    padding: 140px 0; }
    .https404__inner h1 {
      margin-bottom: 20px;
      color: #ffffff; }
    .https404__inner p {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff; }

.partners {
  padding-bottom: 100px; }
  .partners__item {
    width: 100%;
    padding: 35px;
    height: 100%;
    position: relative;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    background: #ffffff; }
    .partners__item-tit {
      padding: 116px 0 0;
      height: 330px !important;
      border: none;
      background: transparent; }
      .partners__item-tit h2 {
        color: #ffffff; }
    .worth .partners__item {
      margin-bottom: 30px;
      height: auto; }
    .partners__item-numb {
      position: absolute;
      top: 35px;
      right: 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #DBDBDB; }
    .partners__item-title {
      margin: 20px 0 10px;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #333333; }
    .partners__item p {
      margin-bottom: 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: #000000; }
    .partners__item ul {
      padding-left: 20px; }
      .partners__item ul li {
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        list-style: disc; }

.worth {
  padding: 100px 0;
  background: #2A5586; }

.license {
  padding: 120px 0; }
  .license__item {
    display: inline-flex !important;
    align-items: center; }
    .license__item-tx {
      width: calc(100% - 480px);
      padding-right: 80px; }
      .license__item-tx p {
        margin-bottom: 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 170%;
        color: #000000; }
    .license__item-title {
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 48px;
      line-height: 110%;
      color: #333333; }
    .license__item-loop {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 3;
      border-radius: 100px;
      background: #2A5586; }
    .license__item-img {
      padding: 20px;
      flex: 0 0 480px;
      max-width: 480px;
      height: 460px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid #DBDBDB;
      border-radius: 8px; }
      .license__item-img img {
        max-width: 100%; }
      .license__item-img:hover .license__item-loop {
        background: #4FCDFF; }

.slick-arrow {
  width: 60px;
  height: 60px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -40px;
  z-index: 2;
  background: #F7F7F7;
  border: none;
  box-shadow: none;
  border-radius: 100px;
  cursor: pointer; }
  .tariffs-slider .slick-arrow {
    width: 125px;
    height: 100%;
    bottom: 0;
    top: 0;
    margin: auto;
    background: transparent; }
    .tariffs-slider .slick-arrow:before {
      content: '';
      width: 60px;
      height: 60px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
      background: #F7F7F7;
      border: none;
      border-radius: 100px;
      cursor: pointer; }
    .tariffs-slider .slick-arrow svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 3; }
  .slick-arrow svg {
    width: 30px;
    height: 30px; }
    .slick-arrow svg use {
      color: #000000; }
  @media screen and (min-width: 991px) {
    .slick-arrow:hover {
      background: #2A5586; }
      .slick-arrow:hover svg use {
        color: #ffffff; }
      .tariffs-slider .slick-arrow:hover {
        background: transparent; }
        .tariffs-slider .slick-arrow:hover:before {
          background: #2A5586; } }

.slick-prev {
  left: 0; }
  .tariffs-slider .slick-prev {
    left: -30px; }
    .tariffs-slider .slick-prev:after {
      content: '';
      width: 150px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(180deg); }

.slick-next {
  left: 140px;
  transform: rotate(180deg); }
  .tariffs-slider .slick-next {
    left: inherit;
    right: -30px; }
    .tariffs-slider .slick-next:after {
      content: '';
      width: 150px;
      height: 100%;
      position: absolute;
      right: -30px;
      top: 0;
      z-index: 1;
      background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(180deg); }

.tariffs-slider .slick-disabled {
  display: none !important; }

.pagingInfo {
  display: inline-block;
  padding: 0 80px; }
  .pagingInfo .dots {
    display: none; }
  .pagingInfo .number-slide {
    display: inline-block;
    position: relative; }
    .pagingInfo .number-slide:before {
      content: '/';
      display: inline-block;
      width: 3px;
      position: absolute;
      top: 2px;
      left: -4px;
      right: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #000000; }
    .pagingInfo .number-slide .numb-prev,
    .pagingInfo .number-slide .numb-next {
      padding: 0 5px;
      display: inline-block;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #000000; }

.contacts__item {
  height: 100%;
  padding: 30px 26px 20px 100px;
  position: relative;
  border: 1px solid #DBDBDB;
  border-radius: 8px; }
  .contacts__item p {
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: #000000; }
  .contacts__item-img {
    position: absolute;
    top: 18px;
    left: 18px; }
  .contacts__item-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #333333; }
  .contacts__item a.contacts__item-title:hover {
    color: #2A5586; }

.map {
  height: 380px;
  margin: 30px 0 100px;
  position: relative; }
  .map #map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }

.requisites {
  padding: 90px 0 120px; }
  .requisites__inner table {
    width: 100%; }
    .requisites__inner table tr th {
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: #000000;
      border-bottom: 1px solid #DBDBDB; }
      .requisites__inner table tr th:first-of-type {
        font-weight: 600;
        font-size: 36px;
        line-height: 120%;
        color: #000000; }
      .requisites__inner table tr th:nth-of-type(2) {
        background: #F7F7F7; }
    .requisites__inner table tr td {
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: #333333; }
      .requisites__inner table tr td:nth-of-type(2) {
        background: #F7F7F7; }
      .requisites__inner table tr td a {
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #2A5586;
        text-decoration: underline; }
        .requisites__inner table tr td a:hover {
          color: #4FCDFF; }
    .requisites__inner table tr th,
    .requisites__inner table tr td {
      width: 33.33%;
      padding: 12px 30px; }
    .requisites__inner table tr.title td {
      padding: 20px 30px;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #333333; }
    .requisites__inner table tr:last-of-type td {
      border-bottom: 1px solid #DBDBDB; }

.integration {
  background: #F7F7F7; }
  .integration__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 55px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    overflow: hidden;
    border-right: none;
    border-bottom: none;
    border-top: none; }
    .integration__list-big {
      padding-bottom: 210px;
      position: relative; }
      .integration__list-big:after {
        content: '';
        width: 100%;
        height: 210px;
        display: block;
        margin-left: -2px;
        position: absolute;
        bottom: 1px;
        left: 1px;
        z-index: 2;
        background-image: url("../images/more.jpg");
        background-size: 100% 210px;
        background-repeat: no-repeat;
        background-color: linear-gradient(180deg, rgba(255, 255, 255, 0) 19.66%, #F7F7F7 100%); }
      .integration__list-big.active {
        padding-bottom: 0; }
        .integration__list-big.active:after {
          display: none; }
        .integration__list-big.active .integration__list-item {
          display: inline-flex !important; }
    .integration__list-item {
      height: 297px;
      padding: 20px 10px;
      flex: 0 0 25%;
      max-width: 25%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid #DBDBDB;
      border-right: 1px solid #DBDBDB;
      background: #ffffff; }
      .integration__list-item:nth-of-type(1), .integration__list-item:nth-of-type(2), .integration__list-item:nth-of-type(3), .integration__list-item:nth-of-type(4) {
        border-top: 1px solid #DBDBDB; }
      .integration__list-item:nth-of-type(n+9) {
        display: none !important; }
      .integration__list-item:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: url(../images/bg-hover3.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
      .integration__list-item:hover {
        z-index: 2;
        box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.15); }
        .integration__list-item:hover:after {
          opacity: 1; }
    .integration__list img {
      position: relative;
      z-index: 2; }
    .integration__list-title {
      position: absolute;
      bottom: 25px;
      z-index: 2;
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: #000000;
      text-align: center; }
  .integration__inner {
    padding-bottom: 80px; }
    .integration__inner h3 {
      margin-bottom: 30px; }
    .integration__inner .button .op {
      display: block; }
    .integration__inner .button .cl {
      display: none; }
    .integration__inner .open .button .op {
      display: none; }
    .integration__inner .open .button .cl {
      display: block; }

.operator__sim {
  display: flex;
  align-items: center;
  margin: 45px 0 20px;
  padding: 30px 50px;
  border: 1px solid #DBDBDB;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .operator__sim-img {
    flex: 0 0 164px; }
  .operator__sim-tx {
    width: calc(100% - 164px);
    padding-left: 40px; }
    .operator__sim-tx input {
      padding: 5px 0;
      width: 100%;
      height: 40px;
      margin-bottom: 0;
      position: relative;
      z-index: 1;
      color: #000000;
      font-weight: 300;
      font-size: 24px;
      line-height: 100%;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      border-bottom: 1px solid #000000;
      background-color: transparent; }
  .operator__sim-tit {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #000000; }
  .operator__sim-inp {
    margin-bottom: 30px;
    position: relative; }
    .operator__sim-inp .close-i {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      cursor: pointer; }

.operator__list-item {
  display: flex;
  align-items: center; }
  .operator__list-item p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    color: #000000; }
  .operator__list-item img {
    width: 100px; }

.operator__list-tit {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #000000; }

.operator__list-tx {
  width: calc(100% - 100px);
  padding-left: 30px; }

.tab-content {
  position: relative; }

.tab-content > .tab-pane {
  display: block;
  opacity: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s; }

.tab-content > .active {
  opacity: 1;
  height: auto;
  position: relative;
  z-index: 2; }

.tariffs-page .tariffs-text {
  padding: 0; }

.tariffs-slider {
  padding: 20px 0 0;
  border-bottom: 1px solid #DBDBDB; }
  .tariffs-slider__slider {
    position: relative; }
    .tariffs-slider__slider .slick-slide.active .tariffs-slider__slider-img .nml {
      display: none !important; }
    .tariffs-slider__slider .slick-slide.active .tariffs-slider__slider-img .nml-hov {
      display: block !important; }
    .tariffs-slider__slider-item {
      width: 120px;
      height: 210px;
      display: block;
      padding-top: 20px;
      position: relative;
      text-align: center; }
      .tariffs-slider__slider-item.active:after {
        content: '';
        width: 100%;
        height: 3px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        background: #2A5586;
        border-radius: 2px; }
      .tariffs-slider__slider-item:hover .tariffs-slider__slider-title {
        color: #2A5586; }
      .tariffs-slider__slider-item:hover .tariffs-slider__slider-img:after {
        opacity: 1; }
      .tariffs-slider__slider-item:hover .tariffs-slider__slider-img img {
        transform: scale(1.1); }
    .tariffs-slider__slider-img {
      width: 100px;
      height: 100px;
      margin: 0 auto 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      position: relative;
      background: #F7F7F7;
      border-radius: 1000px; }
      .tariffs-slider__slider-img .nml {
        display: block; }
      .tariffs-slider__slider-img .nml-hov {
        display: none; }
      .tariffs-slider__slider-img:after {
        content: '';
        width: 120px;
        height: 120px;
        display: block;
        opacity: 0;
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: -1;
        transition: 0.3s;
        border-radius: 1000px;
        background: #F7F7F7; }
      .active .tariffs-slider__slider-img {
        background: #2A5586; }
        .active .tariffs-slider__slider-img:after {
          display: none; }
    .tariffs-slider__slider-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #333333; }
      .active .tariffs-slider__slider-title {
        color: #2A5586; }

.tariffs-text {
  padding: 100px 0;
  background: #F7F7F7; }
  .tariffs-text.tw {
    padding: 0px 0 15px; }
  .tariffs-text.th {
    padding-top: 90px;
    overflow: hidden; }
    .tariffs-text.th .breadcrumb-block {
      padding: 0; }
    .tariffs-text.th .tariffs-text__img {
      height: 460px;
      position: relative; }
      .tariffs-text.th .tariffs-text__img img {
        position: absolute;
        bottom: 0;
        left: 80px; }
    .tariffs-text.th .tariffs-text__inner {
      padding: 67px 0; }
  .tariffs-text.bg {
    background: #ffffff; }
  .tariffs-text__inner h2 {
    margin-bottom: 40px; }
  .tariffs-text__inner p {
    margin-bottom: 60px;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #222222; }
  .tariffs-text__img {
    text-align: center; }
    .tariffs-text__img img {
      margin: auto; }

.tariffs-mailing {
  padding: 100px 0; }
  .tariffs-mailing h3 {
    margin-bottom: 30px; }
  .tariffs-mailing__inner {
    padding: 0 20px;
    margin-bottom: 30px;
    position: relative;
    border: 1px solid #DBDBDB;
    border-radius: 8px; }
    .tariffs-mailing__inner:after {
      content: '';
      width: 25px;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background: #F7F7F7; }
    .tariffs-mailing__inner table {
      width: 100%;
      position: relative;
      z-index: 2; }
      .tariffs-mailing__inner table tr:first-of-type th {
        border-top: none; }
      .tariffs-mailing__inner table tr:last-of-type td {
        border-bottom: none; }
      .tariffs-mailing__inner table tr th {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #000000; }
      .tariffs-mailing__inner table tr td {
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        text-align: center; }
        .tariffs-mailing__inner table tr td:first-of-type {
          text-align: left; }
      .tariffs-mailing__inner table tr th, .tariffs-mailing__inner table tr td {
        padding: 18px 10px;
        border: 1px solid #DBDBDB;
        background: #F7F7F7; }
        .tariffs-mailing__inner table tr th:nth-of-type(1), .tariffs-mailing__inner table tr th:nth-of-type(2), .tariffs-mailing__inner table tr td:nth-of-type(1), .tariffs-mailing__inner table tr td:nth-of-type(2) {
          background: #ffffff; }
        .tariffs-mailing__inner table tr th:first-of-type, .tariffs-mailing__inner table tr td:first-of-type {
          border-left: none; }
        .tariffs-mailing__inner table tr th:last-of-type, .tariffs-mailing__inner table tr td:last-of-type {
          border-right: none; }
      .tariffs-mailing__inner table tr:hover td {
        background: #F2F2F2; }
      .tariffs-mailing__inner table thead tr:nth-of-type(2) th {
        background: #F7F7F7 !important; }
  .tariffs-mailing__tx ol {
    padding-left: 20px; }
    .tariffs-mailing__tx ol li {
      margin-bottom: 13px;
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: #333333; }

.tariffs-advantages {
  padding: 100px 0 50px; }
  .tariffs-advantages__inner {
    padding-top: 70px;
    margin-bottom: 50px; }
    .tariffs-advantages__inner table {
      width: 100%; }
      .tariffs-advantages__inner table tr td {
        padding: 20px 35px;
        border-bottom: 1px solid #DBDBDB;
        border-right: 1px solid #DBDBDB;
        vertical-align: top; }
        .tariffs-advantages__inner table tr td:last-of-type {
          border-right: none; }
      .tariffs-advantages__inner table tr:last-of-type td {
        border-bottom: none; }
  .tariffs-advantages__item p {
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #000000; }
  .tariffs-advantages__item-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #333333; }

.tariffs-schema {
  padding: 100px 0;
  background: #F7F7F7; }
  .tariffs-schema__text {
    width: calc(100% - 570px);
    padding-left: 100px; }
    .tariffs-schema__text h2 {
      margin-bottom: 50px; }
    .tariffs-schema__text p {
      margin-bottom: 55px;
      font-weight: 300;
      font-size: 18px;
      line-height: 170%; }
    .tariffs-schema__text ol {
      counter-reset: myCounter;
      padding-left: 0;
      margin-bottom: 50px; }
      .tariffs-schema__text ol > li {
        min-height: 40px;
        padding-left: 60px;
        margin-bottom: 30px;
        position: relative;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #000000;
        list-style: none; }
        .policy .tariffs-schema__text ol > li {
          font-weight: 400; }
        .tariffs-schema__text ol > li:before {
          counter-increment: myCounter;
          content: counters(myCounter, ".");
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-right: 6px;
          position: absolute;
          top: -5px;
          left: 0;
          background: #ffffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          border-radius: 100px; }
  .tariffs-schema__holder {
    display: flex;
    align-items: flex-start; }
  .tariffs-schema__inner {
    max-width: 570px;
    flex: 0 0 570px;
    position: relative; }
    .tariffs-schema__inner:after {
      content: '';
      width: 475px;
      height: 480px;
      display: inline-block;
      position: absolute;
      right: 52px;
      top: -70px;
      z-index: 1;
      background-image: url("../images/line1.png");
      background-repeat: no-repeat;
      background-size: 475px 480px; }
    .tariffs-schema__inner:before {
      content: '';
      width: 282px;
      height: 286px;
      display: inline-block;
      position: absolute;
      right: -105px;
      bottom: 55px;
      z-index: 1;
      background-image: url("../images/line2.png");
      background-repeat: no-repeat;
      background-size: 282px 286px; }
    .tariffs-schema__inner-in {
      position: relative; }
    .tariffs-schema__inner-tt {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: #000000; }
    .tariffs-schema__inner-time {
      padding: 6px 15px;
      display: inline-block;
      position: absolute;
      z-index: 3;
      border-radius: 40px;
      background: #ffffff;
      font-weight: 600;
      font-size: 15px;
      line-height: 140%;
      color: #2A5586; }
      .tariffs-schema__inner-time.on {
        top: 31%;
        left: 22%;
        transform: rotate(-7deg); }
      .tariffs-schema__inner-time.tw {
        bottom: 43px;
        right: -41px;
        transform: rotate(-14deg); }
    .tariffs-schema__inner-item {
      width: 395px;
      min-height: 70px;
      padding: 28px 25px 28px 140px;
      margin-bottom: 80px;
      position: relative;
      border: 1px solid #2A5586;
      border-radius: 8px;
      background: #ffffff; }
      .tariffs-schema__inner-item.tw {
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 47px; }
      .tariffs-schema__inner-item img {
        position: absolute;
        left: 40px;
        top: 0;
        bottom: 0;
        margin: auto; }
      .tariffs-schema__inner-item-title {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 140%;
        color: #000000; }
      .tariffs-schema__inner-item-tx {
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        color: #2A5586; }

.purpose {
  padding: 100px 0;
  background: #F7F7F7; }
  .purpose-tw {
    padding: 100px 0;
    background: #F7F7F7; }
    .purpose-tw h2 {
      text-align: center; }
    .purpose-tw__inner {
      margin-top: 60px; }
      .purpose-tw__inner > .case__tabs {
        display: block;
        text-align: center; }
        .purpose-tw__inner > .case__tabs .nav-tabs {
          justify-content: center; }
  .purpose h2 {
    text-align: center; }
  .purpose__inner {
    margin-top: 60px;
    min-height: 590px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .purpose__inner .case__tabs {
      width: 100%;
      height: 100%; }
    .purpose__inner .tab-content {
      width: 400px;
      height: 588px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2; }
      .purpose__inner .tab-content .tab-pane {
        height: 100%; }
    .purpose__inner .nav {
      text-align: left; }
      .purpose__inner .nav-item {
        width: 345px;
        margin-bottom: 40px; }
        .purpose__inner .nav-item:nth-of-type(1) .nav-link, .purpose__inner .nav-item:nth-of-type(2) .nav-link, .purpose__inner .nav-item:nth-of-type(3) .nav-link {
          padding: 20px 20px 20px 130px; }
          .purpose__inner .nav-item:nth-of-type(1) .nav-link img, .purpose__inner .nav-item:nth-of-type(2) .nav-link img, .purpose__inner .nav-item:nth-of-type(3) .nav-link img {
            left: 0; }
        .purpose__inner .nav-item:nth-of-type(4), .purpose__inner .nav-item:nth-of-type(5), .purpose__inner .nav-item:nth-of-type(6) {
          position: absolute;
          right: 20px;
          margin-right: 0;
          margin-left: auto; }
          .purpose__inner .nav-item:nth-of-type(4) .nav-link, .purpose__inner .nav-item:nth-of-type(5) .nav-link, .purpose__inner .nav-item:nth-of-type(6) .nav-link {
            padding: 20px 130px 20px 20px; }
            .purpose__inner .nav-item:nth-of-type(4) .nav-link img, .purpose__inner .nav-item:nth-of-type(5) .nav-link img, .purpose__inner .nav-item:nth-of-type(6) .nav-link img {
              right: 0; }
        .purpose__inner .nav-item:nth-of-type(4) {
          top: 50px; }
        .purpose__inner .nav-item:nth-of-type(5) {
          top: 35%; }
        .purpose__inner .nav-item:nth-of-type(6) {
          top: 65%; }
      .purpose__inner .nav-tabs {
        height: 100%;
        display: block;
        padding: 50px 20px;
        position: relative; }
      .purpose__inner .nav-link {
        width: 345px;
        min-height: 100px;
        display: flex;
        align-items: center;
        padding: 0;
        font-weight: 400;
        position: relative;
        z-index: 2;
        background-color: transparent !important;
        border: none;
        border-radius: 8px; }
        .purpose__inner .nav-link img {
          position: absolute;
          top: 0; }
        .purpose__inner .nav-link .tx {
          display: block;
          font-size: 16px;
          line-height: 140%;
          color: #000000; }
        .purpose__inner .nav-link.active {
          background: transparent; }
          .purpose__inner .nav-link.active .tx {
            font-weight: 600; }
        .purpose__inner .nav-link:hover {
          background-color: #ffffff !important;
          background-image: url("../images/svg/bg-line1.svg") !important;
          background-repeat: no-repeat !important;
          background-position: center;
          background-size: cover;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); }
  .purpose__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.mob-d {
  display: none; }

.mob-n {
  display: block; }

@media screen and (max-width: 1200px) {
  .header__contacts {
    margin-right: 20px; }
  .header__menu ul li {
    padding: 0 10px; }
    .header__menu ul li > ul {
      left: -100px; }
  .header__logo {
    max-width: 130px; }
  .footer__menu {
    flex: 0 0 26%; }
  .footer__contacts-tel {
    font-size: 25px;
    list-style: 37px; }
  .purpose__inner .nav-item {
    width: 300px; }
  .purpose__inner .nav-link {
    width: 100%; } }

@media screen and (max-width: 991px) {
  body {
    padding-top: 60px; }
  .case-description__list {
    padding: 70px 55px;
    height: auto;
    margin-bottom: 50px; }
  .header {
    height: 57px;
    padding: 13px 0; }
    .header .footer__contacts {
      text-align: center; }
    .header .footer__contacts-soc {
      display: flex;
      align-items: center;
      justify-content: center; }
      .header .footer__contacts-soc li {
        padding: 0;
        margin-left: 5px; }
        .header .footer__contacts-soc li a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px !important;
          height: 40px !important; }
          .header .footer__contacts-soc li a:after {
            display: none; }
    .header__mob {
      display: block;
      margin-top: 45px; }
    .header__logo {
      max-width: 95px; }
    .header__toggler {
      width: 25px;
      height: 21px;
      display: block; }
    .header__close {
      display: block;
      position: absolute;
      top: 20px;
      right: 15px; }
    .header__menu {
      display: none;
      width: 100%;
      height: 100vh;
      min-height: 100%;
      padding: 80px 0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 20;
      background: #1D2131;
      overflow: auto; }
      .header__menu.open {
        display: block; }
      .header__menu ul {
        height: auto;
        display: block;
        padding-bottom: 96px; }
        .header__menu ul li {
          height: auto;
          display: block; }
          .header__menu ul li.one {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0; }
            .header__menu ul li.one a {
              border: none; }
              .header__menu ul li.one a:after {
                display: none; }
          .header__menu ul li.no-drop {
            width: 100%;
            position: absolute;
            bottom: 47px;
            left: 0; }
            .header__menu ul li.no-drop a:after {
              display: none; }
          .header__menu ul li > a {
            height: auto;
            width: 100%;
            display: block;
            margin-bottom: 0;
            padding: 15px 0;
            position: relative;
            color: #F7F7F7;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            border-bottom: 1px solid rgba(196, 196, 196, 0.1);
            pointer-events: none; }
            .header__menu ul li > a:after {
              content: '';
              width: 15px;
              height: 9px;
              opacity: 1;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              left: inherit;
              margin: auto;
              background-image: url(../images/svg/arrow-ft.svg);
              background-size: 15px 9px;
              background-repeat: no-repeat;
              background-position: center right;
              background-color: transparent; }
          .header__menu ul li.open > a {
            border: none; }
            .header__menu ul li.open > a:after {
              transform: rotate(180deg); }
          .header__menu ul li.open .drop {
            display: block;
            padding: 8px 20px; }
          .header__menu ul li > ul {
            width: 100%;
            padding: 0;
            position: relative;
            top: 0;
            left: 0;
            column-count: 1;
            border-bottom: 1px solid rgba(196, 196, 196, 0.1); }
            .header__menu ul li > ul li {
              width: 100%;
              padding: 0;
              margin-bottom: 18px; }
              .header__menu ul li > ul li a {
                display: block;
                padding: 0;
                font-size: 14px;
                line-height: 17px;
                color: #EDEFF2;
                border: none;
                pointer-events: auto; }
    .header__call {
      display: none; }
    .header__contacts {
      margin-right: 0; }
    .header__info .button {
      width: 24px;
      height: 24px;
      padding: 0;
      display: block;
      position: absolute;
      right: 190px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: transparent;
      overflow: hidden;
      color: transparent;
      border: none; }
      .header__info .button svg {
        width: 24px;
        height: 24px; }
        .header__info .button svg use {
          color: #222222; }
  .about__item .button {
    padding: 15px 18px; }
  .form__top-title {
    width: calc(100% - 200px); }
  .form__inner {
    padding: 75px 35px; }
  .footer__inner {
    display: block;
    padding-bottom: 50px; }
  .footer__left {
    width: 100%; }
  .footer__contacts {
    width: 100%;
    text-align: left;
    margin-top: 50px; }
  .footer__menu {
    margin-bottom: 40px !important; }
  .banner__slider-img {
    width: 80%; }
  .banner-main__img {
    display: none; }
  .news-item__inner {
    display: block; }
  .news-item__description {
    width: 100%;
    padding-right: 0; }
  .news-item__info {
    width: 100%; }
  .news-item__data {
    font-size: 14px;
    line-height: 16px; }
  .case-description:after {
    display: none; }
  .case-description__list {
    height: auto;
    margin-bottom: 50px; }
  .partners__item {
    height: auto;
    margin-bottom: 20px; }
  .license__item-tx {
    width: calc(100% - 280px); }
  .license__item-img {
    padding: 20px;
    flex: 0 0 280px;
    max-width: 280px; }
    .license__item-img img {
      max-width: 100%; }
  .contacts__item {
    height: auto;
    margin-bottom: 30px; }
  .operator__inner {
    margin-bottom: 50px; }
  .tariffs-schema__holder {
    display: flex;
    flex-wrap: wrap; }
  .tariffs-schema__inner {
    order: 2;
    margin-top: 50px; }
  .tariffs-schema__text {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
    order: 1; }
  .footer__left {
    display: block;
    justify-content: space-between; }
  .footer__menu {
    flex: 0 0 20%;
    padding-right: 0;
    margin-bottom: 0; }
  .footer__bottom ul {
    margin-left: 0; }
  .footer__bottom-left {
    display: block; }
  .connect {
    overflow: hidden; }
  .purpose {
    padding-bottom: 50px; }
    .purpose__inner .nav-item {
      width: 250px; }
    .purpose__inner .tab-content {
      width: 250px; }
      .purpose__inner .tab-content img {
        max-width: 100%; } }

@media screen and (max-width: 768px) {
  .header__tel {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url("../images/svg/phone.svg");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    overflow: hidden;
    color: transparent; }
  .header__info .button {
    right: 60px; }
  .button {
    display: inline-block;
    padding: 10px 23px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border-radius: 5px;
    text-align: center; }
    .button.mob {
      display: block; }
    .button-center .button {
      width: 100%;
      margin: 0 0 15px; }
    .button-center.mob {
      display: block; }
    .button-group .button {
      margin-right: 15px; }
  h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 34px;
    line-height: 110%; }
    .policy h1 {
      font-size: 27px; }
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 110%; }
  h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 110%; }
  .breadcrumb-block {
    padding: 20px 0; }
  .breadcrumb .breadcrumb-item {
    font-size: 12px;
    line-height: 16px;
    counter-increment: none; }
    .breadcrumb .breadcrumb-item a {
      font-size: 12px;
      line-height: 16px; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      font-size: 12px;
      line-height: 16px; }
  .title-main {
    margin-bottom: 30px; }
    .title-main__link {
      display: none; }
  .banner__slider .progressBarContainer {
    bottom: 60px; }
    .banner__slider .progressBarContainer__title {
      bottom: -25px;
      font-weight: 300;
      font-size: 10px;
      line-height: 170%; }
    .banner__slider .progressBarContainer .progressBar {
      height: 2px; }
      .banner__slider .progressBarContainer .progressBar .inProgress {
        height: 2px;
        top: 17px !important; }
  .banner__slider-img {
    display: none; }
  .banner__slider-item {
    padding: 43px 0 108px; }
  .banner__slider-categor {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%; }
  .banner__slider-title {
    font-weight: 600;
    font-size: 31px;
    line-height: 100%; }
  .banner__slider-text p {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px; }
  .banner__slider-img {
    display: none; }
  .banner-main h1, .banner-main h2 {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 110%; }
  .banner-main__inner {
    padding: 50px 0; }
  .banner-main__categor {
    font-size: 12px;
    line-height: 16px; }
  .banner-main__text p {
    margin-top: 0 !important;
    font-size: 14px;
    line-height: 20px; }
  .services {
    padding: 55px 0 40px; }
    .services-page {
      padding: 0 0 35px; }
    .services .col-lg-3 {
      margin-bottom: 20px; }
    .services__item {
      padding: 18px 15px;
      min-height: 165px;
      border-radius: 5px;
      background-size: 70px 70px;
      background-position: 100% 75%; }
      .services__item-tag {
        padding: 5px 12px;
        font-size: 10px;
        line-height: 12px;
        border-radius: 0 5px 0 5px; }
      .services__item-img {
        background-image: url("../images/svg/serv-icon12.svg") !important;
        background-position: 97% 75%;
        background-size: 70px 70px; }
        .services__item-img .services__item-title, .services__item-img p {
          display: block;
          color: #333333; }
          .services__item-img .services__item-title br, .services__item-img p br {
            display: none; }
        .services__item-img svg {
          display: none;
          width: 21px !important;
          height: 6px !important; }
          .services__item-img svg.mob {
            display: block !important; }
          .services__item-img svg use {
            color: #333333 !important; }
      .services__item-title {
        min-height: auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px; }
      .services__item p {
        font-weight: 300;
        font-size: 12px;
        line-height: 138%; }
      .services__item svg {
        bottom: 15px;
        left: 18px; }
  .about {
    padding: 55px 0 40px; }
    .about .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .about__text {
      padding-right: 0; }
      .about__text h2 {
        margin-bottom: 20px; }
      .about__text p {
        font-weight: 300;
        font-size: 14px;
        line-height: 170%; }
    .about__item {
      margin-bottom: 25px; }
      .about__item-title {
        margin-bottom: 7px;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%; }
      .about__item p {
        font-weight: 300;
        font-size: 12px;
        line-height: 138%; }
      .about__item .button {
        width: 100%;
        margin: 0; }
  .case {
    padding: 55px 0 35px; }
    .case-page {
      padding: 0 0 35px; }
    .case .col-lg-6 {
      margin-bottom: 18px; }
    .case__item {
      height: auto; }
      .case__item-img {
        height: 130px;
        padding: 15px 10px; }
      .case__item-sticker-item {
        margin-bottom: 8px; }
      .case__item-sticker-title {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 14px; }
        .case__item-sticker-title img {
          width: 18px; }
      .case__item-sticker-tx {
        font-weight: 300;
        font-size: 12px;
        line-height: 138%; }
      .case__item-info {
        padding: 15px 11px 35px; }
        .case__item-info p {
          font-weight: 300;
          font-size: 12px;
          line-height: 138%; }
        .case__item-info svg {
          bottom: 15px;
          left: 11px; }
      .case__item-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px; }
    .case__tabs .nav-link {
      padding: 8px 25px;
      font-size: 12px;
      line-height: 16px; }
    .case-description__text-inner p {
      font-size: 14px;
      line-height: 20px; }
    .case-description__list {
      padding: 20px; }
      .case-description__list-item {
        margin-bottom: 20px; }
      .case-description__list-tx p {
        font-size: 14px;
        line-height: 20px; }
      .case-description__list-title {
        font-size: 18px;
        line-height: 28px; }
      .case-description__list-img {
        flex: 0 0 80px;
        max-width: 80px; }
        .case-description__list-img img {
          max-width: 100%; }
  .connect {
    padding: 55px 0 35px; }
    .connect h2 {
      margin-bottom: 35px; }
    .connect__inner {
      display: block;
      margin-bottom: 0; }
    .connect__item {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 55px; }
      .connect__item:after {
        display: none; }
      .connect__item p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px; }
      .connect__item-img {
        width: 68px;
        height: auto;
        margin: auto; }
        .connect__item-img img {
          max-width: 100%; }
      .connect__item-numb {
        margin: 24px 0 8px;
        width: 43px;
        height: 43px;
        font-weight: 600;
        font-size: 17px;
        line-height: 120%; }
      .connect__item-tx {
        font-size: 14px;
        line-height: 20px; }
  .news {
    padding: 55px 0 35px; }
    .news-page {
      padding: 0 0 35px; }
    .news .col-lg-4 {
      margin-bottom: 20px; }
    .news-item__description h2 {
      margin: 20px 0;
      font-size: 28px;
      line-height: 110%; }
    .news-item__description p {
      font-size: 14px;
      line-height: 20px; }
    .news-item__description ol > li {
      padding-left: 40px;
      font-size: 14px;
      line-height: 20px; }
      .news-item__description ol > li:before {
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 20px; }
    .news-item__description ul li {
      font-size: 14px;
      line-height: 20px; }
    .news-item__description table tr td, .news-item__description table tr th {
      font-size: 14px;
      line-height: 20px; }
    .news__item {
      height: auto;
      padding: 25px 20px 65px; }
      .news__item p {
        font-weight: 300;
        font-size: 12px;
        line-height: 138%; }
      .news__item svg {
        bottom: 15px;
        left: 20px; }
      .news__item-data {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 12px;
        line-height: 170%; }
      .news__item-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px; }
        .news__item-img .news__item-title {
          font-size: 25px;
          line-height: 30px; }
      .news__item-img {
        padding-bottom: 95px; }
        .news-item__info .news__item-img {
          height: 250px; }
        .news__item-img .button {
          left: 20px; }
  .form {
    padding: 60px 0; }
    .news-item__info .form {
      padding: 20px; }
    .form__inner {
      padding: 40px 20px; }
    .form__top {
      margin-bottom: 27px;
      align-items: flex-start; }
      .form__top h2 {
        margin-bottom: 13px; }
      .form__top img {
        width: 76px; }
      .form__top-title {
        width: calc(100% - 90px); }
        .form__top-title p {
          font-weight: 300;
          font-size: 14px;
          line-height: 150%; }
    .form__cell {
      margin-bottom: 25px; }
      .form__cell label {
        font-size: 14px !important;
        line-height: 17px !important; }
      .form__cell input {
        font-size: 18px;
        line-height: 100%; }
    .form__bottom {
      display: block;
      margin-top: 25px;
      text-align: center; }
    .form__tx {
      margin-left: 0;
      margin-top: 13px;
      font-weight: 300;
      font-size: 10px;
      line-height: 138%; }
  .footer__left {
    display: block;
    padding-bottom: 48px;
    position: relative; }
  .footer__bottom {
    display: block; }
    .footer__bottom-left {
      display: block; }
  .footer__inner {
    padding-bottom: 25px; }
  .footer__menu {
    flex: 0 0 100%;
    margin-bottom: 0 !important; }
    .footer__menu br {
      display: none; }
    .footer__menu-title {
      width: 100%;
      display: block;
      margin-bottom: 0;
      padding: 15px 0;
      position: relative;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      border-bottom: 1px solid rgba(196, 196, 196, 0.1);
      pointer-events: none; }
      .footer__menu-title:after {
        content: '';
        width: 15px;
        height: 9px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url("../images/svg/arrow-ft.svg");
        background-size: 15px 9px;
        background-repeat: no-repeat;
        background-position: center right; }
      .footer__menu-title.one {
        border: none;
        background-image: none;
        pointer-events: auto;
        position: absolute;
        bottom: 0;
        left: 0; }
        .footer__menu-title.one:after {
          display: none; }
    .footer__menu ul {
      display: none;
      padding: 3px 21px; }
      .footer__menu ul li {
        margin-bottom: 11px; }
    .footer__menu-btn {
      display: none;
      padding-left: 21px;
      margin-top: 0; }
    .footer__menu.open {
      margin-bottom: 25px; }
      .footer__menu.open .footer__menu-title {
        pointer-events: none;
        border-color: transparent;
        border-bottom: 1px solid rgba(196, 196, 196, 0.1); }
        .footer__menu.open .footer__menu-title:after {
          transform: rotate(180deg); }
      .footer__menu.open ul {
        display: block; }
      .footer__menu.open .footer__menu-btn {
        display: block; }
  .footer__contacts {
    margin-top: 35px;
    text-align: center; }
    .footer__contacts-tel {
      font-size: 24px;
      line-height: 100%; }
    .footer__contacts-inf {
      font-size: 16px;
      line-height: 16px; }
    .footer__contacts .button {
      display: block;
      width: 100%;
      background: #2A5586;
      color: #ffffff;
      border-color: #2A5586; }
    .footer__contacts-soc {
      text-align: center; }
      .footer__contacts-soc li {
        padding: 0 !important;
        display: inline-flex !important; }
        .footer__contacts-soc li a {
          display: inline-flex !important;
          padding: 0 !important;
          width: 40px !important;
          height: 40px !important; }
          .footer__contacts-soc li a:after {
            display: none !important; }
  .footer__bottom {
    display: block;
    padding-bottom: 45px;
    text-align: center; }
    .footer__bottom-tx {
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 16px; }
    .footer__bottom-link {
      font-size: 12px;
      line-height: 16px; }
    .footer__bottom-left ul {
      margin-bottom: 15px;
      display: block; }
      .footer__bottom-left ul li {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 16px; }
        .footer__bottom-left ul li:after {
          display: none !important; }
  .pagination-bl {
    padding-bottom: 35px; }
  .pagination .page-item {
    margin: 0 2px;
    font-size: 14px !important;
    line-height: 20px !important; }
    .pagination .page-item.next, .pagination .page-item.prev {
      width: 30px;
      height: 30px;
      min-height: inherit;
      margin: 0 10px;
      position: relative; }
      .pagination .page-item.next .page-link, .pagination .page-item.prev .page-link {
        padding: 0; }
      .pagination .page-item.next .arrow, .pagination .page-item.prev .arrow {
        width: 30px;
        height: 30px;
        position: absolute; }
        .pagination .page-item.next .arrow svg, .pagination .page-item.prev .arrow svg {
          width: 18px;
          height: 18px; }
      .pagination .page-item.next .tt, .pagination .page-item.prev .tt {
        display: none; }
    .pagination .page-item.next .arrow {
      right: 0 !important;
      top: 0 !important; }
    .pagination .page-item.prev .arrow {
      left: 0 !important;
      top: 0 !important; }
  .pagination .page-link {
    width: 30px !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 20px !important; }
  .terms ol > li {
    margin-bottom: 40px; }
    .terms ol > li > ol {
      margin-top: 0; }
      .terms ol > li > ol > li {
        font-size: 14px;
        line-height: 20px; }
        .terms ol > li > ol > li:before {
          font-size: 14px;
          line-height: 20px; }
  .https404__inner {
    padding: 50px 0; }
    .https404__inner p {
      font-size: 14px;
      line-height: 20px; }
  .https404__img {
    width: 300px;
    margin: auto; }
    .https404__img img {
      max-width: 100%; }
  .partners {
    padding-bottom: 35px; }
    .partners__item {
      padding: 20px; }
      .partners__item-tit {
        height: auto !important; }
      .partners__item img {
        width: 80px; }
      .partners__item p, .partners__item ul li {
        font-size: 14px;
        line-height: 20px; }
      .partners__item-numb {
        top: 20px;
        font-size: 12px;
        line-height: 16px; }
        .worth .partners__item-numb {
          display: none; }
      .partners__item-title {
        font-size: 18px;
        line-height: 28px; }
  .worth {
    padding: 50px 0; }
  .pagingInfo {
    width: 100%;
    text-align: center; }
  .license {
    padding: 55px 0 75px; }
    .license__slider .slick-next {
      right: 0;
      left: 130px;
      margin: auto; }
    .license__slider .slick-prev {
      right: 130px;
      left: 0;
      margin: auto; }
    .license__item {
      display: block !important; }
      .license__item-title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 32px;
        line-height: 110%; }
      .license__item-tx {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px; }
        .license__item-tx p {
          font-size: 14px;
          line-height: 20px; }
      .license__item-img {
        margin: 0 auto 50px; }
  .contacts__item {
    padding: 20px 20px 20px 77px; }
    .contacts__item-title {
      font-size: 18px;
      line-height: 28px; }
    .contacts__item-img img {
      width: 50px; }
  .map {
    margin-bottom: 50px; }
  .requisites {
    padding-bottom: 70px; }
    .requisites__t {
      position: relative;
      padding-top: 50px; }
      .requisites__t:before {
        content: '';
        width: 70px;
        height: 50px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url("../images/svg/swipe.svg");
        background-repeat: no-repeat;
        background-size: 70px 50px; }
    .requisites__inner {
      overflow: auto; }
      .requisites__inner table tr th {
        font-size: 18px;
        line-height: 28px; }
        .requisites__inner table tr th:first-of-type {
          font-size: 25px;
          line-height: 35px; }
      .requisites__inner table tr td {
        font-size: 14px;
        line-height: 24px; }
        .requisites__inner table tr td a {
          font-size: 14px;
          line-height: 24px; }
      .requisites__inner table tr td, .requisites__inner table tr th {
        padding: 10px; }
      .requisites__inner table tr.title td {
        padding: 10px;
        font-size: 18px;
        line-height: 28px; }
  .integration__inner {
    padding-bottom: 50px; }
  .integration__list-big {
    padding-bottom: 0; }
    .integration__list-big:after {
      display: none; }
  .integration__list-item {
    height: 200px;
    flex: 0 0 50%;
    max-width: 50%; }
  .operator__list-item img {
    width: 80px; }
  .operator__sim {
    padding: 20px; }
    .operator__sim-img {
      flex: 0 0 100px; }
      .operator__sim-img img {
        width: 100px; }
    .operator__sim-tx {
      width: calc(100% - 100px); }
    .operator__sim-inp input {
      font-size: 18px;
      line-height: 28px; }
  .tariffs-text {
    padding: 55px 0; }
    .tariffs-text.th {
      padding-top: 20px; }
      .tariffs-text.th .tariffs-text__inner {
        padding: 40px 0; }
      .tariffs-text.th .tariffs-text__img {
        height: auto; }
        .tariffs-text.th .tariffs-text__img img {
          position: relative;
          left: 0; }
    .tariffs-text__inner p {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 24px; }
    .tariffs-text__img {
      margin-top: 30px; }
      .tariffs-text__img.mob {
        display: none; }
  .tariffs-mailing {
    padding: 55px 0; }
    .tariffs-mailing__t {
      position: relative;
      padding-top: 50px; }
      .tariffs-mailing__t:before {
        content: '';
        width: 70px;
        height: 50px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url("../images/svg/swipe.svg");
        background-repeat: no-repeat;
        background-size: 70px 50px; }
    .tariffs-mailing__inner {
      overflow: auto;
      position: relative; }
      .tariffs-mailing__inner table {
        width: 768px; }
        .tariffs-mailing__inner table tr th, .tariffs-mailing__inner table tr td {
          padding: 10px;
          font-size: 14px;
          line-height: 24px; }
  .tariffs-slider {
    padding-top: 20px; }
    .tariffs-slider__slider-img {
      width: 70px;
      height: 70px; }
      .tariffs-slider__slider-img img {
        max-width: 50px; }
    .tariffs-slider__slider-title {
      font-size: 14px;
      line-height: 20px; }
    .tariffs-slider__slider-item {
      padding-top: 0;
      height: 160px; }
    .tariffs-slider__slider-img:after {
      display: none; }
  .tariffs-advantages {
    padding: 55px 0; }
    .tariffs-advantages__inner {
      padding-top: 20px; }
      .tariffs-advantages__inner table tr {
        display: block; }
        .tariffs-advantages__inner table tr td {
          display: block;
          padding: 15px 0;
          border: none;
          border-bottom: 1px solid #DBDBDB !important; }
    .tariffs-advantages__item-title {
      font-size: 18px;
      line-height: 28px; }
    .tariffs-advantages__item p {
      font-size: 14px;
      line-height: 24px; }
  .tariffs-schema {
    padding: 50px 0;
    overflow: hidden; }
    .tariffs-schema__text h2 {
      margin-bottom: 30px; }
    .tariffs-schema__text p {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 24px; }
    .tariffs-schema__text ol li {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 24px; }
      .tariffs-schema__text ol li:before {
        font-size: 14px;
        line-height: 24px; }
    .tariffs-schema__inner {
      max-width: 280px;
      flex: 0 0 280px; }
      .tariffs-schema__inner:before {
        content: '';
        width: 82px;
        height: 86px;
        right: -30px;
        bottom: 111px;
        background-size: 82px 86px; }
      .tariffs-schema__inner:after {
        width: 75px;
        height: 80px;
        top: 73px;
        background-size: 75px 80px; }
      .tariffs-schema__inner.mob {
        display: none; }
      .tariffs-schema__inner-time {
        padding: 4px 7px;
        font-size: 9px;
        line-height: 12px; }
        .tariffs-schema__inner-time.tw {
          bottom: 72px;
          right: -15px; }
        .tariffs-schema__inner-time.on {
          top: 32%;
          left: 44%; }
      .tariffs-schema__inner-tt {
        font-size: 12px;
        line-height: 14px; }
      .tariffs-schema__inner-item {
        margin-bottom: 35px !important;
        width: 280px;
        padding: 15px 15px 15px 60px; }
        .tariffs-schema__inner-item img {
          width: 30px;
          left: 10px; }
        .tariffs-schema__inner-item-title {
          font-size: 14px;
          line-height: 18px; }
        .tariffs-schema__inner-item-tx {
          font-size: 12px;
          line-height: 22px; }
  .modal-body {
    padding: 20px 30px; }
    .modal-body__title {
      font-size: 18px;
      line-height: 28px; }
    .modal-body__tx {
      font-size: 14px;
      line-height: 24px; }
  .purpose {
    padding: 55px 0; }
    .purpose-tw {
      padding: 55px 0; }
      .purpose-tw .tab-content .purpose__content {
        display: none; }
    .purpose__inner .nav {
      column-count: 1; }
      .purpose__inner .nav-tabs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 0; }
      .purpose__inner .nav-item {
        flex: 0 0 100%;
        width: 100%;
        margin: 0;
        position: relative !important;
        right: 0 !important;
        top: 0 !important; }
        .purpose__inner .nav-item:nth-of-type(4) .nav-link img, .purpose__inner .nav-item:nth-of-type(5) .nav-link img, .purpose__inner .nav-item:nth-of-type(6) .nav-link img {
          left: 0;
          right: inherit; }
      .purpose__inner .nav-link {
        margin: 0;
        padding: 20px 20px 20px 80px !important; }
        .purpose__inner .nav-link img {
          width: 70px;
          top: 18px; }
        .purpose__inner .nav-link .tx {
          font-size: 14px;
          line-height: 24px; }
    .purpose__inner .tab-content {
      width: 100%;
      height: auto;
      position: relative; }
      .purpose__inner .tab-content img {
        max-width: 100%; }
  .tariffs-slider .slick-next {
    right: -12px; }
  .tariffs-slider .slick-left {
    left: -12px; }
  .tariffs-slider .slick-next:after,
  .tariffs-slider .slick-prev:after {
    width: 100px; }
  .tariffs-slider .slick-next:after {
    right: 0; }
  .tariffs-slider .slick-arrow:before {
    width: 45px;
    height: 45px; }
  .tariffs-slider .slick-arrow svg {
    width: 20px;
    height: 20px; }
  .purpose__inner .tab-content {
    display: none; }
  .mob-d {
    display: block; }
  .mob-n {
    display: none; } }

@media screen and (max-width: 575px) {
  .operator__sim-img {
    display: none; }
  .operator__sim-tx {
    width: 100%;
    padding-left: 0; } }
