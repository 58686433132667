  @import "variables.scss";



  @font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-300.eot');
    src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-500.eot');
    src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-700.eot');
    src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }


  * {
    margin: 0;
    padding: 0;
  }


  body {
    min-width: 320px;
    padding-top: 80px;

    font-family: $font-family;
    font-weight: 500;

    &.overfl {
      overflow: hidden;
      height: 100vh;
    }
  }

  .start-page {
    padding: 30px;

    &-logo {
      display: block;
      margin-bottom: 30px;
    }

    ul {
      display: inline-block;
      vertical-align: top;
      padding-right: 60px;

      li {
        padding: 5px 0;

        b {
          font-size: 20px;
        }

        a {
          font-size: 16px;
          line-height: 140%;
          color: $black;

          &:hover {
            color: $active;
          }
        }
      }
    }
  }

  div,
  span,
  a,
  input,
  textarea,
  select,
  button,
  .button {
    &:focus {
      outline: none;
    }
  }



  ul {
    margin-bottom: 0;

    li {
      list-style: none;
    }
  }

  a {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
    }
  }

  .container {
    max-width: 100%;

    .header &,
    .footer & {
      max-width: 100%;

      @media screen and (min-width: 1420px) {
        max-width: 1390px;
      }
    }

    .form & {
      @media screen and (min-width: 1360px) {
        max-width: 1330px;
      }
    }

    @media screen and (min-width: 1230px) {
      max-width: 1200px;
    }
  }

  h1 {
    margin-bottom: 40px;
  }

  h2, h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 100%;
    color: $black2;
  }

  h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
  }

  .title {
    &-main {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 0;
      }

      &__link {
        display: inline-block;

        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: $active;

        &:hover {
          color: $active-hover;
        }
      }
    }
  }

  .button {
    display: inline-block;

    box-shadow: none !important;
    cursor: pointer;
    border: none;
    transition: 0.3s;

    &.mob {
      display: none;
    }

    &-group {
      .button {
        display: inline-block;
        margin-right: 20px;
      }

      &.mob {
        display: none;
      }
    }

    &-center {
      margin: 10px 0;

      text-align: center;

      &.mob {
        display: none;
      }

      .button {
        margin: 0 10px;
      }
    }

    &-primary {
      display: inline-flex;
      align-items: center;
      padding: 8px 20px;

      border-radius: 8px;
      background: $active;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.04em;
      color: $white;
      border: 2px solid $active;

      .header & {
        font-size: 14px;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        use {
          color: $white;
        }
      }

      &:hover {
        background: $blue;
        border-color: $blue;
        color: $active;

        svg {
          use {
            color: $active;
          }
        }
      }
    }

    &-secondary {
      padding: 15px 20px;

      border-radius: 8px;
      background: $white;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.04em;
      color: $active;
      border: 2px solid $white;

      &:hover {
        background: transparent;
        border-color: $blue;
        color: $blue;
      }
    }

    &-bord {
      padding: 15px 20px;

      border-radius: 8px;
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.04em;
      color: $white;
      border: 2px solid $white;

      &:hover {
        background: $blue;
        border-color: $blue;
        color: $active;
      }
    }

    &-bord2 {
      padding: 15px 20px;

      border-radius: 8px;
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.04em;
      color: $active;
      border: 2px solid $active;

      &:hover {
        background: $blue;
        border-color: $blue;
        color: $active;
      }
    }

    &-big {
      padding: 15px 40px;
    }
  }

  .header {
    width: 100%;
    height: 80px;
    //padding: 16px 0;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    background: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &__toggler {
      display: none;
    }

    &__mob {
      display: none;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      display: block;
    }

    &__close {
      display: none;
    }

    &__menu {
      height: 80px;
      display: flex;
      align-items: center;

      ul {
        height: 100%;
        position: relative;

        display: flex;
        align-items: center;
        margin-bottom: 0;

        li {
          height: 100%;
          padding: 0 16px;

          a {
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;

            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $black2;
            text-transform: uppercase;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              display: block;
              opacity: 0;

              position: absolute;
              bottom: 0;
              left: 0;

              background: $active;
            }

            &:hover {
              color: $active;
            }
          }

          @media screen and (min-width: 991px) {
            &:hover {
              & > a {
                color: $active;

                &:after {
                  opacity: 1;
                }
              }

              & > ul {
                height: auto;
                display: block;
              }
            }
          }

          & > ul {
            width: 920px;
            padding: 57px 55px;

            display: none;

            position: absolute;
            top: 80px;
            left: -60px;

            webkit-column-count: 3;
            column-count: 3;

            background: $black4;

            li {
              height: auto;
              padding: 0 10px 0 0;
              margin-bottom: 20px;

              a {
                height: auto;
                text-transform: none;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: $white;

                &:after {
                  display: none;
                }

                &:hover {
                  color: $blue;
                }
              }
            }
          }
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__contacts {
      text-align: right;
      margin-right: 65px;
    }

    &__tel {
      display: block;

      font-size: 18px;
      line-height: 100%;
      color: $black3;
      font-weight: 500;

      &:hover {
        color: $active;
      }
    }

    &__call {
      font-size: 13px;
      line-height: 14px;
      color: $active;

      &:hover {
        color: $active-hover;
      }
    }
  }

  .banner {
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__slider {
      position: relative;

      &.sliderContainer {


      }

      .progressBarContainer {
        display: flex;
        align-items: center;
        width: 100%;

        position: absolute;
        bottom: 90px;
        left: 0;

        &__title {
          position: absolute;
          bottom: -40px;

          font-weight: 300;
          font-size: 15px;
          line-height: 170%;
          color: $white;
        }

        div {
          display: block;
          width: 100%;
          padding: 0;
          cursor: pointer;
          margin-right: 20px;
          color: $white;

          position: relative;

          .progressBar {
            width: 100%;
            height: 3px;
            background-color: rgba(255, 255, 255, 0.2);
            display: block;
            border-radius: 2px;

            .inProgress {
              background-color: rgba(255, 255, 255, 1);
              width: 0%;
              height: 3px;
              position: absolute;
              top: 25px;
              border-radius: 2px;
            }
          }
        }
      }


      &-item {
        padding: 100px 0 110px;
      }

      &-inner {

      }

      &-text {
        padding-left: 5px;

        p {
          margin-bottom: 40px;

          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          color: $white;
        }
      }

      &-categor {
        margin-bottom: 13px;

        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $blue;
        text-transform: uppercase;
      }

      &-title {
        margin-bottom: 20px;

        font-weight: 600;
        font-size: 64px;
        line-height: 100%;
        color: $white;
      }
    }

    &-main {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      h1, h2 {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 64px;
        line-height: 110%;
        color: $white;

        & + p {
          margin-top: -20px;
        }
      }

      &__inner {
        padding: 80px 0 40px;
      }

      &__categor {
        margin-bottom: 15px;

        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $blue;
      }

      &__text {
        p {
          margin-bottom: 40px;

          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          color: $white;
        }
      }
    }
  }

  .services {
    padding: 80px 0;

    background: $bg;

    &-page {
      padding: 0 0 90px;
    }

    .col-lg-3 {
      margin-bottom: 30px;
    }

    &__item {
      width: 100%;
      height: 100%;
      min-height: 270px;
      padding: 20px 50px 50px 20px;

      position: relative;

      border-radius: 8px;
      border: 1px solid $bord;
      background-color: $white;

      background-size: 140px 140px;
      background-repeat: no-repeat;
      background-position: 100% 85%;
      overflow: hidden;
      transition: 0.3s;

      &-im-hov {
        opacity: 0;
        width: 100%;
        height: 100%;
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s;
      }

      /*&:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s;
      }*/


      &:hover {
        &:after {
          opacity: 1;
        }

        .services__item-im-hov {
          opacity: 1;
          z-index: 1;
        }

        /*&.services__item-icon1 {
          &:after {
            background-image: url("../images/serv-icon-hov1.jpg");
          }
        }
        &.services__item-icon2 {
          &:after {
            background-image: url("../images/serv-icon-hov2.jpg");
          }
        }
        &.services__item-icon3 {
          &:after {
            background-image: url("../images/serv-icon-hov3.jpg");
          }
        }
        &.services__item-icon4 {
          &:after {
            background-image: url("../images/serv-icon-hov4.jpg");
          }
        }
        &.services__item-icon5 {
          &:after {
            background-image: url("../images/serv-icon-hov5.jpg");
          }
        }
        &.services__item-icon6 {
          &:after {
            background-image: url("../images/serv-icon-hov6.jpg");
          }
        }
        &.services__item-icon7 {
          &:after {
            background-image: url("../images/serv-icon-hov7.jpg");
          }
        }
        &.services__item-icon8 {
          &:after {
            background-image: url("../images/serv-icon-hov8.jpg");
          }
        }
        &.services__item-icon9 {
          &:after {
            background-image: url("../images/serv-icon-hov9.jpg");
          }
        }
        &.services__item-icon10 {
          &:after {
            background-image: url("../images/serv-icon-hov10.jpg");
          }
        }
        &.services__item-icon11 {
          &:after {
            background-image: url("../images/serv-icon-hov11.jpg");
          }
        }
        &.services__item-icon12 {
          &:after {
            background-image: url("../images/serv-icon-hov12.jpg");
          }
        }*/

        .services__item-title,
        svg use {
          color: $white;
        }

        p {
          display: none;
        }

        svg {
          display: block;
          opacity: 0;
          transition: 0.3s;

          &.hov {
            display: block;
            width: 61px !important;
            height: 6px !important;
            opacity: 1;
          }

          use {

          }
        }
      }

      a {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &-tag {
        padding: 8px 18px;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        border-radius: 0 8px 0 8px;

        &-profitable {
          color: $white;
          background: $red;
        }
        &-new {
          color: $active;
          background: $blue;
        }
        &-actual {
          color: $white;
          background: $orange;
        }
        &-soon {
          color: $white;
          background: $active;
        }
      }

      &-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        border: none;

        &:hover {
          background-color: transparent;
        }

        p {
          display: none;
        }

        svg {
          width: 61px!important;
          height: 6px!important;

          position: relative;
          z-index: 2;



          &.hov {
            display: block;
            opacity: 0;
          }

          use {
            color: $white!important;
          }
        }
      }

      &-title {
        min-height: 55px;
        margin-bottom: 15px;

        position: relative;
        z-index: 2;

        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: $black2;
        transition: 0.3s;

        .services__item-img & {
          color: $white;
        }
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: $black;
        transition: 0.3s;
      }

      svg {
        width: 21px;
        height: 6px;

        position: absolute;
        bottom: 30px;
        left: 20px;
        z-index: 2;
        transition: 0.3s;

        use {
          color: $black;
        }
      }
    }

    &-main {
      .col-sm-12 {
        display: none;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          display: block;
        }

        .open & {
          display: block;
        }
      }

      &.open {
        .col-sm-12 {
          display: block;
        }

        .button-primary {
          color: transparent;
          position: relative;

          &:after {
            content: 'Скрыть';

            position: absolute;
            left: 0;
            right: 0;
            text-align: center;

            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.04em;
            color: #ffffff;
          }
        }
      }
    }
  }

  .about {
    padding: 110px 0 40px;

    &-bg {
      background: $bg;
    }

    &__text {
      padding-right: 30px;

      h2 {
        margin-bottom: 30px;
      }

      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 170%;
        color: $color;
      }
    }

    &__item {
      margin-bottom: 60px;

      .button {
        margin: 30px 0;
      }

      &-title {
        margin-bottom: 12px;

        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        color: $black3;
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        color: $black3;
      }
    }
  }

  .case {
    padding: 100px 0;

    background: $bg;


    .col-lg-6 {
      margin-bottom: 30px;
    }

    &-page {
      padding: 0;

      position: relative;

      background: $white;
      overflow: hidden;
    }

    &-description {
      position: relative;


      &:after {
        content: '';
        width: 30%;
        height: 100%;
        display: block;

        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        background: $bg;

      }

      &__text {
        &-inner {
          padding-bottom: 50px;

          h2 {
            margin-bottom: 40px;
          }

          h3 {
            margin-bottom: 20px;
          }

          p {
            margin-bottom: 20px;

            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black;

            & + h3 {
              margin-top: 30px;
            }
          }
        }
      }

      &__list {
        height: 100%;
        padding: 100px 55px;

        position: relative;
        z-index: 3;

        background: $bg;

        &-item {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
        }

        &-img {
          flex: 0 0 116px;
          max-width: 116px;
        }

        &-tx {
          width: calc(100% - 116px);
          padding-left: 15px;

          p {
            margin-bottom: 0;

            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            color: $black3;
          }
        }

        &-title {
          margin-bottom: 5px;

          font-weight: 600;
          font-size: 22px;
          line-height: 150%;
          color: $black3;
        }
      }
    }

    &__item {
      height: 100%;

      position: relative;
      overflow: hidden;

      border-radius: 8px;
      overflow: hidden;
      border: 1px solid $bord2;
      background-color: $white;


      a {
        width: 100%;
        height: 100%;

        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
      }

      &:hover {
        .case__item-info {
          &:after {
            opacity: 1;
          }
        }

        .case__item-img {
          transform: scale(1.1);
        }

        .case__item-title {
          color: $active;
        }

        svg {
          width: 61px;
          height: 6px;
          transition: 0.3s;

          &.ar {
            display: block;
            opacity: 0;
          }

          &.ar-hov {
            display: block;
            opacity: 1;
          }

          use {
            color: $active;
          }
        }
      }

      &-img {
        height: 258px;
        width: 100%;
        padding: 20px;

        position: relative;
        z-index: 1;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: 0.3s;

      }

      &-sticker {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;

        &-item {
          margin-bottom: 20px;
        }

        &-title {
          margin-bottom: 10px;

          font-size: 24px;
          line-height: 18px;
          color: $white;
        }

        &-tx {
          font-weight: 300;
          font-size: 18px;
          line-height: 18px;
          color: $white;
        }
      }

      &-info {
        padding: 20px 20px 60px;
        background: $white;

        position: relative;
        z-index: 2;

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;

          background-image: url("../images/bg-hover2.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          transition: 0.3s;
        }

        p {
          margin-bottom: 0;

          position: relative;
          z-index: 2;

          font-weight: 300;
          font-size: 16px;
          line-height: 150%;
          color: $black3;
        }

        svg {
          width: 21px;
          height: 6px;

          position: absolute;
          bottom: 25px;
          left: 20px;
          z-index: 2;

          &.ar {
            display: block;
          }

          &.ar-hov {
            display: block;
            opacity: 0;
          }

          use {
            color: $black2;
          }
        }
      }

      &-title {
        margin-bottom: 10px;

        position: relative;
        z-index: 2;

        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: $black2;
      }
    }

    &__tabs {
      overflow: hidden;
      .nav {
        &-tabs {
          margin-bottom: 30px;

          border: none;

          .integration & {
            margin-bottom: 50px;
          }
        }

        &-item {
          margin-right: 7px;
          margin-bottom: 10px;
        }

        &-link {
          padding: 10px 31px;

          font-weight: 600;
          font-size: 14px;
          line-height: 26px;
          color: $black2;

          border-radius: 30px;
          background: $bg;

          .integration &,
          .purpose-tw & {
            background: $white;
          }

          &:hover {
            color: $white;
            background: $active;
          }

          &.active {
            color: $white;
            background: $active;
          }
        }
      }

      .tab {
        &-content {

        }

        &-pane {

        }
      }
    }
  }

  .connect {
    padding: 85px 0 70px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h2 {
      margin-bottom: 80px;

      color: $white;
      text-align: center;
    }

    &__inner {
      margin-bottom: 65px;

      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &__item {
      padding: 0 25px;
      flex: 0 0 33.333%;
      max-width: 100%;

      position: relative;

      text-align: center;

      &:after {
        content: '';
        width: 60%;
        height: 1px;
        display: inline-block;

        position: absolute;
        right: -30%;
        top: 198px;

        background-color: rgba(255, 255, 255, 0.2);
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        color: $white;
      }

      &-tx {
        font-weight: 300;
        font-size: 17px;
        line-height: 140%;
        color: $blue3;
      }

      &-img {
        height: 120px;
        margin-bottom: 50px;
      }

      &-numb {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        border-radius: 100px;
        background: $white;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: $blue2;
      }
    }
  }

  .news {
    padding: 100px 0;

    .col-lg-4 {
      margin-bottom: 30px;
    }

    &-page {
      padding: 0;
    }

    &-al {
      padding-bottom: 70px;

      background: $bg;
    }

    &-item {

      &__inner {
        display: flex;
        align-items: flex-start;
        padding-bottom: 85px;
      }

      &__description {
        width: calc(100% - 370px);
        padding-right: 80px;

        h2 {
          margin: 40px 0 22px;

          font-weight: 600;
          font-size: 36px;
          line-height: 120%;
          color: $black3;
        }

        h3 {
          margin: 40px 0 22px;

          font-weight: 600;
          font-size: 22px;
          line-height: 120%;
          color: $black3;
        }

        img {
          margin: 20px 0;
        }

        p {
          margin-bottom: 17px;

          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          color: $black;

          .policy & {
            font-weight: 400;
          }
        }

        .table-container {
          padding: 0 15px;
          margin-bottom: 20px;

          border: 1px solid $bord2;
          border-radius: 8px;
        }

        table {
          width: 100%;


          tr {
            th {
              font-weight: 600;
            }

            td {
              font-weight: 300;
            }

            th,td {
              padding: 17px 10px;

              font-size: 16px;
              line-height: 160%;
              color: $black;

              border-bottom: 1px solid $bord2;
            }

            &:last-of-type {
              td {
                border: none;
              }
            }
          }
        }

        ul {
          padding-left: 20px;

          li {
            margin-bottom: 10px;

            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black;
            list-style: disc;

            .policy & {
              font-weight: 400;
            }
          }
        }

        ol {
          counter-reset: myCounter;
          padding-left: 0;
          margin-bottom: 35px;

          & > li {
            min-height: 40px;
            padding-left: 60px;
            margin-bottom: 20px;

            position: relative;

            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black;
            list-style: none;

            .policy & {
              font-weight: 400;
            }

            &:before {
              counter-increment: myCounter;
              content: counters(myCounter, ".") ;

              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              margin-right: 6px;

              position: absolute;
              top: -5px;
              left: 0;

              background: $bg;
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              color: $black;
              border-radius: 100px;
            }
          }
        }
      }

      &__data {
        margin-bottom: 15px;

        font-weight: 300;
        font-size: 15px;
        line-height: 170%;
        color: $black;
      }

      &__info {
        width: 370px;
        padding-top: 40px;

        .operator & {
          padding-top: 0;
        }
      }
    }

    &__item {
      height: 100%;
      padding: 30px 30px 80px;

      position: relative;

      border: 1px solid $bord2;
      border-radius: 8px;
      background-color: $white;

      .news-item__info & {
        height: 370px;
      }

      .button {
        position: relative;
        z-index: 2;
      }

      &-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        p {
          color: $white!important;
        }

        .button {
          position: absolute;
          left: 30px;
          bottom: 30px;
        }

        &:hover {
          .button {
            color: $active;
            background: $white;
          }

          p, .news__item-title {
            color: $black!important;
          }
        }
      }



      &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        background-image: url("../images/news-hover-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s;
      }


      &:hover {
        border-color: transparent;
        background-image: none!important;



        &:after {
          opacity: 1;
          }

        svg {
          &.ar {
            display: block;
            opacity: 0;
          }

          &.ar-hov {
            width: 61px;
            height: 6px;
            display: block;
            opacity: 1;
          }
        }

      }

      a {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
      }

      p {
        margin-bottom: 0;

        position: relative;
        z-index: 2;

        color: $black3;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
      }

      svg {
        width: 21px;
        height: 6px;

        position: absolute;
        bottom: 25px;
        left: 30px;
        z-index: 2;
        transition: 0.3s;

        &.ar {
          display: block;
        }

        &.ar-hov {
          display: block;
          opacity: 0;
        }

        use {
          color: $black;
        }
      }

      &-data {
        margin-bottom: 40px;

        position: relative;
        z-index: 2;

        font-weight: 300;
        font-size: 15px;
        line-height: 170%;
        color: $black;
      }

      &-title {
        margin-bottom: 15px;

        position: relative;
        z-index: 2;

        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: $black3;

        .news__item-img & {
          color: $white;
          font-weight: 600;
          font-size: 36px;
          line-height: 100%;
        }
      }
    }
  }

  .form {
    padding: 80px 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .news-item__info & {
      padding: 45px;
      margin-bottom: 30px;

      background: $bg;
      border-radius: 8px;
    }

    .modal-body & {
      padding: 30px 0 0;

      border-radius: 0;
      background: transparent;
    }

    &__inner {
      padding: 75px 60px;

      background: $white;
    }

    &__top {
      margin-bottom: 83px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .news-item__info & {
        display: block;
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: 20px;

        .news-item__info & {
          font-weight: 600;
          font-size: 22px;
          line-height: 120%;
        }
      }

      &-title {
        width: calc(100% - 450px);

        font-weight: 300;
        font-size: 18px;
        line-height: 120%;
        color: $black;

        .news-item__info & {
          width: 100%;
          padding: 15px 0 22px;
        }
      }
    }

    &-fr {

    }

    &__bottom {
      display: flex;
      align-items: center;
      margin-top: 60px;

      .news-item__info & ,
      .modal-body & {
        display: block;
        margin-top: -10px;
      }
    }

    &__error {
      input {
        border-color: $red2!important;
      }

      span {
        color: $red2;
        font-size: 13px;
        line-height: 100%;
      }
    }

    &__tx {
      margin-left: 60px;

      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: $black;

      .news-item__info &,
      .modal-body & {
        margin-left: 0;
        margin-top: 19px;
        color: $color;

        a {
          color: $color;
        }
      }

      a {
        color: $black;
        text-decoration: underline;

        &:hover {
          color: $active;
        }
      }
    }

    &__cell {
      position: relative;

      .news-item__info & ,
      .modal-body & {
        margin-bottom: 33px;
      }

      input {
        padding: 5px 0;
        width: 100%;
        height: 40px;
        margin-bottom: 0;

        position: relative;
        z-index: 1;

        color: $black;
        font-weight: 300;
        font-size: 24px;
        line-height: 100%;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid $black;
        background-color: transparent;

        &.form__input_effect,
        &.form__textarea_effect{
          & ~ label{
            position: absolute;
            left: 0;
            top: 11px;
            width: 100%;

            transition: 0.3s;
            z-index: 10;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: $black;

            &:hover {
              cursor: text;
            }
          }

          &:focus ~ label,
          &.has-content ~ label {
            bottom: inherit;
            top: -18px;
            transition: 0.3s;
          }
        }
      }
    }
  }

  .footer {
    background: $black4;

    &__inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 48px 0 115px;
    }

    &__left {
      display: flex;
      align-items: flex-start;
    }

    &__contacts {
      text-align: right;

      .button {
        padding: 10px 28px;

        &:hover {
          color: $blue;
          border-color: $blue;
          background: transparent;
        }
      }

      &-tel {
        display: inline-block;
        margin-bottom: 13px;

        font-size: 28px;
        line-height: 100%;
        color: $white;

        &:hover {
          color: $gray;
        }
      }

      &-inf {
        padding: 15px 0 25px;

        font-weight: 300;
        font-size: 18px;
        line-height: 16px;
        color: $white;
      }

      &-soc {
        margin-bottom: 30px;

        li {
          display: inline-block;
          margin-left: 5px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;

            border-radius: 100px;
            border: 1px solid $bord3;

            svg {
              width: 18px;
              height: 18px;

              use {
                color: $white;
              }
            }

            &:hover {
              background: $white;
              border-color: $white;

              svg {
                use {
                  color: $black4;
                }
              }
            }
          }
        }
      }
    }

    &__menu {
      flex: 0 0 35%;
      padding-right: 20px;

      &-title {
        margin-bottom: 25px;
        display: inline-block;

        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: $bg;

        & + .footer__menu-title {
          margin-top: 25px;
        }

        &:hover {
          color: $gray;
        }
      }

      &-btn {
        margin-top: 15px;

        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $blue;
        cursor: pointer;

        & + ul {
          margin-top: 45px;
        }

        .open & {
          display: none;
        }

        &:hover {
          color: $white;
        }
      }

      &-nn {
        ul {
          li {
            &:nth-of-type(n+9) {
              display: none;
            }
          }
        }
      }

      &-fv {
        ul {
          li {
            &:nth-of-type(n+6) {
              display: none;
            }
          }
        }
      }


      ul {
        li {
          margin-bottom: 8px;

          a {
            font-size: 14px;
            line-height: 16px;
            color: $gray;

            &:hover {
              color: $white;
            }
          }
        }

        &.open {
          li {
            display: block!important;
          }

          & + .footer__menu-btn {
            display: none;
          }
        }
      }
    }

    &__bottom {
      padding: 20px 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-top: 1px solid $bord3;

      &-left {
        display: flex;
        align-items: center;
      }

      &-tx {
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: $gray;
      }

      &-link {
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: $gray;

        &:hover {
          color: $white;
        }
      }

      ul {
        margin-left: 100px;

        li {
          display: inline-block;

          a {
            font-weight: 300;
            font-size: 13px;
            line-height: 16px;
            color: $gray;

            &:hover {
              color: $white;
            }
          }

          &:first-of-type {
            &:after {
              content: '|';
              display: inline-block;
              padding-left: 5px;

              font-weight: 300;
              font-size: 13px;
              line-height: 16px;
              color: $gray;
            }
          }
        }
      }
    }
  }

  .breadcrumb {
    padding: 0;

    &-block {
      padding: 42px 0 40px;

      .breadcrumb {
        padding: 0;
        margin: 0;

        background: transparent;

        &-item {
          font-weight: 300;
          font-size: 14px;
          line-height: 170%;
          color: $gray2;
          margin-bottom: 0;

          &:first-of-type {
            &:before {
              display: none!important;
            }
          }

          .https404 & {
            color: $white;
          }

          a {
            font-weight: 300;
            font-size: 14px;
            line-height: 170%;
            color: $gray2;

            .https404 & {
              color: $white;
            }

            &:hover {
              color: $active;

              .https404 & {
                color: $gray;
              }
            }
          }

          & + .breadcrumb-item {
            &:before {
              content: '–';
              padding-right: 8px;
              padding-left: 8px;

              font-weight: 300;
              font-size: 14px;
              line-height: 170%;
              color: $gray2;

              .https404 & {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    &-bl {
      padding: 20px 0 95px;
    }

    .page {
      &-item {
        margin: 0 5px;

        a, span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;

          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: $black;
          border-radius: 100px!important;
          border: none;
          box-shadow: none!important;

          &:hover {
            background: $active;
            color: $white;
          }
        }

        span {
          cursor: inherit!important;
          color: $black;
          background: transparent;

          &:hover {
            color: $black;
            background: transparent;
          }
        }

        &.active {
          background: $active;
          color: $white;
          border-radius: 100px;

          a, span {
            background: $active;
            color: $white;
          }
        }

        &.prev,
        &.next {
          margin: 0 10px;
          min-height: 40px;

          .page-link {
            width: auto;
            height: auto;

            position: relative;

            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            color: $black;

            background: transparent!important;

            &:hover {
              color: $black;

              .arrow {
                background: $active;

                svg {
                  use {
                    color: $white;
                  }
                }
              }
            }
          }

          .arrow {
            width: 40px;
            height: 40px;

            background: $bg;

            svg {
              width: 25px;
              height: 25px;

              use {
                color: $black;
              }
            }
          }

          span {
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
          }
        }

        &.prev {
          margin-right: 50px;

          .page-link {
            padding-left: 55px;

            .arrow {
              position: absolute;
              left: -29px;
              top: 9px;
            }
          }
        }

        &.next {
          margin-left: 50px;

          .page-link {
            .arrow {
              margin-left: 15px;

              position: absolute;
              right: -66px;
              top: 9px;

              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .terms {
    ol {
      counter-reset: myCounter;
      padding-left: 0;

      & > li {
        padding-left: 0;
        margin-bottom: 60px;

        position: relative;

        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $black;

        list-style: none;

        &:before {
          counter-increment: myCounter;
          content: counters(myCounter, ".") ". ";

          width: auto;
          height: auto;
          display: inline-block;
          margin-right: 6px;

          position: relative;
          top: 0;

          font-weight: 600;
          font-size: 22px;
          line-height: 120%;
          color: $black;
          background: transparent;
        }

        & > ol {
          margin-top: 17px;
          padding-left: 0;

          & > li {
            margin-bottom: 20px;
            padding-left: 0;

            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black;

            text-transform: initial;

            &:before {
              counter-increment: myCounter;
              content: counters(myCounter, ".") ". ";
              display: inline-block;
              font-weight: 300;
              font-size: 16px;
              line-height: 160%;
              color: $black;
            }

            & > ol {
              margin-top: 17px;
              padding-left: 0;

              & > li {
                margin-bottom: 20px;
                padding-left: 0;

                font-weight: 300;
                font-size: 16px;
                line-height: 160%;
                color: $black;

                text-transform: initial;

                &:before {
                  counter-increment: myCounter;
                  content: counters(myCounter, ".") ". ";
                  display: inline-block;
                  font-weight: 300;
                  font-size: 16px;
                  line-height: 160%;
                  color: $black;
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    padding: 0 15px;

    &-dialog {
      max-width: 370px;
      margin: auto;
    }

    &-content{
      border-radius: 8px;
      border: none;

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        opacity: 1;
        z-index: 3;
      }
    }

    &-body {
      padding: 45px 50px 60px;

      &__img {
        margin-bottom: 20px;
      }

      &__title {
        margin-bottom: 15px;

        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $black;
      }

      &__tx {
        margin-bottom: 30px;

        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: $black;
      }
    }
  }

  .https404 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__img {
      margin-bottom: 40px;
    }

    &__inner {
      margin: auto;
      text-align: center;
      padding: 140px 0;

      h1 {
        margin-bottom: 20px;

        color: $white;
      }

      p {
        margin-bottom: 30px;

        font-weight: 300;
        font-size: 18px;
        line-height: 120%;
        color: $white;
      }
    }
  }

  .partners {
    padding-bottom: 100px;

    &__item {
      width: 100%;
      padding: 35px;
      height: 100%;

      position: relative;

      border: 1px solid $bord2;
      border-radius: 8px;
      background: $white;


      &-tit {
        padding: 116px 0 0;
        height: 330px!important;

        border: none;
        background: transparent;

        h2 {
          color: $white;
        }
      }

      .worth & {
        margin-bottom: 30px;

        height: auto;
      }

      &-numb {
        position: absolute;
        top: 35px;
        right: 30px;

        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $bord2;
      }

      &-title {
        margin: 20px 0 10px;

        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        color: $black2;
      }

      p {
        margin-bottom: 0;

        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: $black;
      }

      ul {
        padding-left: 20px;
        //margin-top: 15px;

        li {
          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          color: $black;
          list-style: disc;
        }
      }
    }
  }

  .worth {
    padding: 100px 0;

    background: $active;

    &__inner {

    }
  }

  .license {
    padding: 120px 0;

    &__slider {

    }

    &__inner {

    }

    &__item {
      display: inline-flex!important;
      align-items: center;

      &-tx {
        width: calc(100% - 480px);
        padding-right: 80px;

        p {
          margin-bottom: 0;

          font-weight: 300;
          font-size: 18px;
          line-height: 170%;
          color: $black;
        }
      }

      &-title {
        margin-bottom: 30px;

        font-weight: 600;
        font-size: 48px;
        line-height: 110%;
        color: $black2;
      }

      &-loop {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 3;

        border-radius: 100px;
        background: $active;
      }

      &-img {
        padding: 20px;
        flex: 0 0 480px;

        max-width: 480px;
        height: 460px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        border: 1px solid $bord2;
        border-radius: 8px;

        img {
          //max-width: 280px;
          max-width: 100%;
        }

        &:hover {
          .license__item-loop {
            background: $blue;
          }
        }
      }
    }
  }

  .slick {
    &-arrow {
      width: 60px;
      height: 60px;
      display: inline-flex!important;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: -40px;
      z-index: 2;

      background: $bg;
      border: none;
      box-shadow: none;
      border-radius: 100px;
      cursor: pointer;

      .tariffs-slider & {
        width: 125px;
        height: 100%;
        bottom: 0;
        top: 0;
        margin: auto;

        background: transparent;

        &:before {
          content: '';
          width: 60px;
          height: 60px;
          display: block;

          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;

          background: $bg;
          border: none;
          border-radius: 100px;
          cursor: pointer;
        }

        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 3;
        }
      }

      svg {
        width: 30px;
        height: 30px;

        use {
          color: $black;
        }
      }

      @media screen and (min-width: 991px) {
        &:hover {
          background: $active;

          svg {
            use {
              color: $white;
            }
          }

          .tariffs-slider & {
            background: transparent;

            &:before {
              background: $active;
            }
          }
        }
      }
    }

    &-prev {
      left: 0;

      .tariffs-slider & {
        left: -30px;

        &:after {
          content: '';
          width: 150px;
          height: 100%;

          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;


          background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
          transform: rotate(180deg);
        }
      }

    }

    &-next {
      left: 140px;
      transform: rotate(180deg);

      .tariffs-slider & {
        left: inherit;
        right: -30px;

        &:after {
          content: '';
          width: 150px;
          height: 100%;

          position: absolute;
          right: -30px;
          top: 0;
          z-index: 1;

          background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
          transform: rotate(180deg);
        }
      }
    }

    &-disabled {
      .tariffs-slider & {
        display: none!important;
      }
    }

    &-dots {

    }
  }

  .pagingInfo {
    display: inline-block;
    padding: 0 80px;

    .dots {
      display: none;
    }

    .number {
      &-slide {
        display: inline-block;

        position: relative;

        &:before {
          content: '/';
          display: inline-block;
          width: 3px;

          position: absolute;
          top: 2px;
          left: -4px;
          right: 0;
          bottom: 0;
          z-index: 1;
          margin: auto;

          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: $black;
        }

        .numb-prev,
        .numb-next {
          padding: 0 5px;
          display: inline-block;

          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: $black;
        }
      }

    }
  }

  .contacts {
    &__item {
      height: 100%;
      padding: 30px 26px 20px 100px;

      position: relative;

      border: 1px solid $bord2;
      border-radius: 8px;

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 140%;
        color: $black;
      }

      &-img {
        position: absolute;
        top: 18px;
        left: 18px;
      }

      &-title {
        margin-bottom: 15px;

        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: $black2;
      }

      a.contacts__item-title {
        &:hover {
          color: $active;
        }
      }
    }
  }

  .map {
    height: 380px;
    margin: 30px 0 100px;

    position: relative;

    #map {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .requisites {
    padding: 90px 0 120px;

    &__inner {
      table {
        width: 100%;

        tr {
          th {
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            color: $black;
            border-bottom: 1px solid $bord2;

            &:first-of-type {
              font-weight: 600;
              font-size: 36px;
              line-height: 120%;
              color: $black;
            }

            &:nth-of-type(2) {
              background: $bg;
            }
          }

          td {
            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black2;

            &:nth-of-type(2) {
              background: $bg;
            }

            a {
              font-weight: 300;
              font-size: 16px;
              line-height: 160%;
              color: $active;
              text-decoration: underline;

              &:hover {
                color: $blue;
              }
            }
          }

          th,
          td {
            width: 33.33%;
            padding: 12px 30px;
          }

          &.title {
            td {
              padding: 20px 30px;
              font-weight: 600;
              font-size: 22px;
              line-height: 120%;
              color: $black2;
            }
          }

          &:last-of-type {
            td {
              border-bottom: 1px solid $bord2;
            }
          }
        }
      }
    }
  }

  .integration {
    background: $bg;

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 55px;

      border: 1px solid $bord2;
      border-radius: 8px;
      overflow: hidden;
      border-right: none;
      border-bottom: none;
      border-top: none;

      &-big {
        padding-bottom: 210px;
        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 210px;
          display: block;
          margin-left: -2px;

          position: absolute;
          bottom: 1px;
          left: 1px;
          z-index: 2;

          background-image: url("../images/more.jpg");
          background-size: 100% 210px;
          background-repeat: no-repeat;
          background-color: linear-gradient(180deg, rgba(255, 255, 255, 0) 19.66%, #F7F7F7 100%);
        }

        &.active {
          padding-bottom: 0;

          &:after {
            display: none;
          }

          .integration__list-item {
            display: inline-flex!important;
          }
        }
      }

      &.active {

      }

      &-item {
        height: 297px;
        padding: 20px 10px;
        flex: 0 0 25%;
        max-width: 25%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        position: relative;

        border-bottom: 1px solid $bord2;
        border-right: 1px solid $bord2;
        background: $white;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          border-top: 1px solid $bord2;
        }

        &:nth-of-type(n+9) {
          display: none!important;
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-image: url(../images/bg-hover3.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          -webkit-transition: 0.3s;
          transition: 0.3s;
        }

        &:hover {
          z-index: 2;
          box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.15);

          &:after {
            opacity: 1;
          }
        }
      }

      img {
        position: relative;
        z-index: 2;
      }

      &-title {
        position: absolute;
        bottom: 25px;
        z-index: 2;

        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: $black;
        text-align: center;
      }
    }

    &__inner {
      padding-bottom: 80px;

      h3 {
        margin-bottom: 30px;
      }

      .button {
        .op {
          display: block;
        }

        .cl {
          display: none;
        }
      }

      .open  {
        .button {
          .op {
            display: none;
          }

          .cl {
            display: block;
          }
        }
      }
    }
  }

  .operator {
    &__inner {

    }

    &__sim {
      display: flex;
      align-items: center;
      margin: 45px 0 20px;
      padding: 30px 50px;

      border: 1px solid $bord2;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &-img {
        flex: 0 0 164px;
      }

      &-tx {
        width: calc(100% - 164px);
        padding-left: 40px;

        input {
          padding: 5px 0;
          width: 100%;
          height: 40px;
          margin-bottom: 0;

          position: relative;
          z-index: 1;

          color: $black;
          font-weight: 300;
          font-size: 24px;
          line-height: 100%;
          border-radius: 0;
          border: 0;
          box-shadow: none;
          border-bottom: 1px solid $black;
          background-color: transparent;


        }
      }

      &-tit {
        margin-bottom: 10px;

        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: $black;
      }

      &-inp {
        margin-bottom: 30px;

        position: relative;

        .close-i {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;

          cursor: pointer;
        }
      }
    }

    &__list {
      &-item {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;

          font-weight: 300;
          font-size: 24px;
          line-height: 100%;
          color: $black;
        }

        img {
          width: 100px;
        }
      }

      &-tit {
        display: block;
        margin-bottom: 10px;

        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: $black;
      }

      &-tx {
        width: calc(100% - 100px);
        padding-left: 30px;
      }
    }
  }

  .tab-content {
    position: relative;
  }

  .tab-content>.tab-pane {
    display: block;
    opacity: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s;
  }

  .tab-content>.active {
    opacity: 1;
    height: auto;
    position: relative;
    z-index: 2;
  }

  .tariffs {
    &-page {
      .tariffs-text {
        padding: 0;
      }
    }

    &-slider {
      padding: 20px 0 0;

      border-bottom: 1px solid $bord2;

      &__slider {
        position: relative;

        .slick-slide.active {
          .tariffs-slider__slider-img .nml {
            display: none!important;
          }

          .tariffs-slider__slider-img .nml-hov {
            display: block!important;
          }
        }

        &-item {
          width: 120px;
          height: 210px;
          display: block;
          padding-top: 20px;

          position: relative;

          text-align: center;

          &.active {
            &:after {
              content: '';
              width: 100%;
              height: 3px;
              display: block;

              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              z-index: 2;

              background: $active;
              border-radius: 2px;
            }
          }

          &:hover {
            .tariffs-slider__slider-title {
              color: $active;
            }

            .tariffs-slider__slider-img {
              &:after {
                opacity: 1;
              }

              img {
                transform: scale(1.1);
              }
            }
          }
        }

        &-img {
          width: 100px;
          height: 100px;
          margin: 0 auto 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s;

          position: relative;

          background: $bg;
          border-radius: 1000px;

          .nml {
            display: block;
          }

          .nml-hov {
            display: none;
          }

          &:after {
            content: '';
            width: 120px;
            height: 120px;
            display: block;
            opacity: 0;

            position: absolute;
            top: -10px;
            left: -10px;
            z-index: -1;

            transition: 0.3s;
            border-radius: 1000px;
            background: $bg;
          }

          .active & {
            background: $active;

            &:after {
              display: none;
            }
          }
        }

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: $black2;

          .active & {
            color: $active;
          }
        }
      }
    }

    &-text {
      padding: 100px 0;

      background: $bg;

      &.tw {
        padding: 0px 0 15px;
      }

      &.th {
        padding-top: 90px;

        overflow: hidden;

        .breadcrumb-block {
          padding: 0;
        }

        .tariffs-text__img {
          height: 460px;
          position: relative;

          img {
            position: absolute;
            bottom: 0;
            left: 80px;
          }
        }

        .tariffs-text__inner {
          padding: 67px 0;
        }
      }

      &.bg {
        background: $white;
      }

      &__inner {
        h2 {
          margin-bottom: 40px;
        }

        p {
          margin-bottom: 60px;

          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          color: $black3;
        }
      }

      &__img {
        text-align: center;

        img {
          margin: auto;
        }
      }
    }

    &-mailing {
      padding: 100px 0;

      h3 {
        margin-bottom: 30px;
      }

      &__inner {
        padding: 0 20px;
        margin-bottom: 30px;

        position: relative;

        border: 1px solid $bord2;
        border-radius: 8px;

        &:after {
          content: '';
          width: 25px;
          height: 100%;
          display: block;

          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;

          background: $bg;
        }

        table {
          width: 100%;

          position: relative;
          z-index: 2;

          tr {
            &:first-of-type {
              th {
                border-top: none;
              }
            }

            &:last-of-type {
              td {
                border-bottom: none;
              }
            }

            th {
              text-align: center;
              font-weight: 600;
              font-size: 16px;
              line-height: 120%;
              color: $black;
            }

            td {
              font-weight: 300;
              font-size: 16px;
              line-height: 160%;
              color: $black;
              text-align: center;

              &:first-of-type {
                text-align: left;
              }
            }

            th, td {
              padding: 18px 10px;

              border: 1px solid $bord2;
              background: $bg;

              &:nth-of-type(1),
              &:nth-of-type(2) {
                background: $white;
              }

              &:first-of-type {
                border-left: none;
              }

              &:last-of-type {
                border-right: none;
              }
            }

            &:hover {
              td {
                background: $bg2;
              }
            }
          }


          thead {
            tr {
              &:nth-of-type(2) {
                th {
                  background: $bg!important;
                }
              }
            }
          }
        }
      }

      &__tx {
        ol {
          padding-left: 20px;

          li {
            margin-bottom: 13px;

            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
            color: $black2;
          }
        }
      }
    }

    &-advantages {
      padding: 100px 0 50px;

      &__inner {
        padding-top: 70px;
        margin-bottom: 50px;

        table {
          width: 100%;

          tr {
            td {
              padding: 20px 35px;

              border-bottom: 1px solid $bord2;
              border-right: 1px solid $bord2;
              vertical-align: top;

              &:last-of-type {
                border-right: none;
              }
            }

            &:last-of-type {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }

      &__item {

        p {
          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          color: $black;
        }

        &-title {
          margin-bottom: 10px;

          font-weight: 600;
          font-size: 22px;
          line-height: 120%;
          color: $black2;
        }
      }
    }

    &-schema {
      padding: 100px 0;

      background: $bg;

      &__text {
        width: calc(100% - 570px);
        padding-left: 100px;

        h2 {
          margin-bottom: 50px;
        }

        p {
          margin-bottom: 55px;

          font-weight: 300;
          font-size: 18px;
          line-height: 170%;
        }

        ol {
          counter-reset: myCounter;
          padding-left: 0;
          margin-bottom: 50px;

          & > li {
            min-height: 40px;
            padding-left: 60px;
            margin-bottom: 30px;

            position: relative;

            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            color: $black;
            list-style: none;

            .policy & {
              font-weight: 400;
            }

            &:before {
              counter-increment: myCounter;
              content: counters(myCounter, ".") ;

              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              margin-right: 6px;

              position: absolute;
              top: -5px;
              left: 0;

              background: $white;
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              color: $black;
              border-radius: 100px;
            }
          }
        }
      }

      &__holder {
        display: flex;
        align-items: flex-start;
      }



      &__inner {
        max-width: 570px;
        flex: 0 0 570px;

        position: relative;

        &:after {
          content: '';
          width: 475px;
          height: 480px;
          display: inline-block;

          position: absolute;
          right: 52px;
          top: -70px;
          z-index: 1;

          background-image: url("../images/line1.png");
          background-repeat: no-repeat;
          background-size: 475px 480px;
        }

        &:before {
          content: '';
          width: 282px;
          height: 286px;
          display: inline-block;

          position: absolute;
          right: -105px;
          bottom: 55px;
          z-index: 1;

          background-image: url("../images/line2.png");
          background-repeat: no-repeat;
          background-size: 282px 286px;
        }

        &-in {
          position: relative;
        }

        &-tt {
          font-weight: 300;
          font-size: 14px;
          line-height: 150%;
          color: $black;
        }

        &-time {
          padding: 6px 15px;
          display: inline-block;

          position: absolute;
          z-index: 3;

          border-radius: 40px;
          background: $white;
          font-weight: 600;
          font-size: 15px;
          line-height: 140%;
          color: $active;

          &.on {
            top: 31%;
            left: 22%;
            transform: rotate(-7deg);
          }

          &.tw {
            bottom: 43px;
            right: -41px;
            transform: rotate(-14deg);
          }
        }

        &-item {
          width: 395px;
          min-height: 70px;
          padding: 28px 25px 28px 140px;
          margin-bottom: 80px;

          position: relative;
          //z-index: 2;

          border: 1px solid $active;
          border-radius: 8px;
          background: $white;

          &.tw {
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 47px;
          }

          img {
            position: absolute;
            left: 40px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          &-title {
            margin-bottom: 5px;

            font-size: 16px;
            line-height: 140%;
            color: $black;
          }

          &-tx {
            font-weight: 600;
            font-size: 13px;
            line-height: 140%;
            color: $active;
          }
        }

        }

    }
  }

  .purpose {
    padding: 100px 0;

    background: $bg;

    &-tw {
      padding: 100px 0;

      background: $bg;

      h2 {
        text-align: center;
      }

      &__inner {
        margin-top: 60px;

        & > .case__tabs {
          display: block;
          text-align: center;

          .nav-tabs {
            justify-content: center;
          }
        }
      }
    }

    h2 {
      text-align: center;
    }

    &__inner {
      margin-top: 60px;
      min-height: 590px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      position: relative;

      .case__tabs {
        width: 100%;
        height: 100%;
      }

      .tab-content {
        width: 400px;
        height: 588px;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;

        .tab-pane {
          height: 100%;
        }
      }

      .nav {
        //column-count: 2;
        text-align: left;

        &-item {
          width: 345px;
          margin-bottom: 40px;

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            .nav-link {
              padding: 20px 20px 20px 130px;

              img {
                left: 0;
              }
            }
          }

          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {

            position: absolute;
            right: 20px;

            margin-right: 0;
            margin-left: auto;


            .nav-link {
              padding: 20px 130px 20px 20px;

              img {
                right: 0;
              }
            }
          }

          &:nth-of-type(4) {
            top: 50px;
          }
          &:nth-of-type(5) {
            top: 35%;
          }
          &:nth-of-type(6) {
            top: 65%;
          }
        }

        &-tabs {
          height: 100%;
          display: block;
          padding: 50px 20px;

          position: relative;
        }

        &-link {
          width: 345px;
          min-height: 100px;

          display: flex;
          align-items: center;
          padding: 0;
          font-weight: 400;

          position: relative;
          z-index: 2;

          background-color: transparent!important;
          border: none;

          border-radius: 8px;


          img {
            position: absolute;
            top: 0;
          }

          .tx {
            display: block;

            font-size: 16px;
            line-height: 140%;
            color: $black;
          }

          &.active {
            background: transparent;

            .tx {
              font-weight: 600;
            }
          }

          &:hover {
            background-color: $white!important;
            background-image: url("../images/svg/bg-line1.svg")!important;
            background-repeat: no-repeat!important;
            background-position: center;
            background-size: cover;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mob-d {
    display: none;
  }

  .mob-n {
    display: block;
  }


  @media screen and (max-width: 1200px) {
    .header {
      &__contacts {
        margin-right: 20px;
      }

      &__menu {
        ul {
          li {
            padding: 0 10px;

            & > ul {
              left: -100px;
            }
          }
        }
      }

      &__logo {
        max-width: 130px;
      }
    }

    .footer {
      &__menu {
        flex: 0 0 26%;
      }

      &__contacts {
        &-tel {
          font-size: 25px;
          list-style: 37px;
        }
      }
    }

    .purpose {
      &__inner {
        .nav {
          &-item {
            width: 300px;
          }

          &-link {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    body {
      padding-top: 60px;
    }

    .case {
      &-description {
        &__list {
          padding: 70px 55px;
          height: auto;
          margin-bottom: 50px;
        }
      }
    }
    .header {
      height: 57px;
      padding: 13px 0;

      .footer__contacts {
        text-align: center;
      }

      .footer__contacts-soc {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          padding: 0;
          margin-left: 5px;

          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px !important;
            height: 40px !important;

            &:after {
              display: none;
            }
          }
        }
      }

      &__mob {
        display: block;
        margin-top: 45px;
      }

      &__logo {
        max-width: 95px;
      }

      &__toggler {
        width: 25px;
        height: 21px;
        display: block;
      }

      &__close {
        display: block;

        position: absolute;
        top: 20px;
        right: 15px;
      }

      &__menu {
        display: none;
        width: 100%;
        height: 100vh;
        min-height: 100%;
        padding: 80px 0;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;

        background: $black4;
        overflow: auto;

        &.open {
          display: block;
        }

        ul {
          height: auto;
          display: block;
          padding-bottom: 96px;

          li {
            height: auto;
            display: block;

            &.one {
              width: 100%;

              position: absolute;
              bottom: 0;
              left: 0;

              a {
                border: none;

                &:after {
                  display: none;
                }
              }
            }

            &.no-drop {
              width: 100%;
              position: absolute;
              bottom: 47px;
              left: 0;

              a {
                &:after {
                  display: none;
                }
              }
            }

            & > a {
              height: auto;
              width: 100%;
              display: block;
              margin-bottom: 0;
              padding: 15px 0;

              position: relative;

              color: $bg;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              border-bottom: 1px solid rgba(196, 196, 196, 0.1);
              pointer-events: none;

              &:after {
                content: '';
                width: 15px;
                height: 9px;
                opacity: 1;

                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                left: inherit;
                margin: auto;

                background-image: url(../images/svg/arrow-ft.svg);
                background-size: 15px 9px;
                background-repeat: no-repeat;
                background-position: center right;
                background-color: transparent;
              }
            }

            &.open {
              & > a {
                border: none;

                &:after {
                  transform: rotate(180deg);
                }
              }

              .drop {
                display: block;
                padding: 8px 20px;
              }
            }

            & > ul {
              width: 100%;
              padding: 0;

              position: relative;
              top: 0;
              left: 0;

              column-count: 1;

              border-bottom: 1px solid rgba(196, 196, 196, 0.1);

              li {
                width: 100%;
                padding: 0;
                margin-bottom: 18px;

                a {
                  display: block;
                  padding: 0;

                  font-size: 14px;
                  line-height: 17px;
                  color: $bord;
                  border: none;
                  pointer-events: auto;
                }
              }
            }
          }
        }
      }

      &__call {
        display: none;
      }

      &__tel {

      }

      &__contacts {
        margin-right: 0;
      }


      &__info {
        .button {
          width: 24px;
          height: 24px;
          padding: 0;
          display: block;

          position: absolute;
          right: 190px;
          top: 0;
          bottom: 0;
          margin: auto;

          background: transparent;
          overflow: hidden;
          color: transparent;
          border: none;

          svg {
            width: 24px;
            height: 24px;

            use {
              color: $black3;
            }
          }
        }
      }
    }

    .about {
      &__item {
        .button {
          padding: 15px 18px;
        }
      }
    }

    .form {
      &__top {
        &-title {
          width: calc(100% - 200px);
        }
      }

      &__inner {
        padding: 75px 35px;
      }
    }

    .footer {
      &__inner {
        display: block;
        padding-bottom: 50px;
      }

      &__left {
        width: 100%;
      }

      &__contacts {
        width: 100%;

        text-align: left;
        margin-top: 50px;
      }

      &__menu {
        margin-bottom: 40px!important;
      }


    }

    .banner {
      &__slider {
        &-img {
          width: 80%;
          //display: none;
        }
      }

      &-main {
        &__img {
          display: none;
        }
      }
    }

    .news {
      &-item {
        &__inner {
          display: block;
        }

        &__description {
          width: 100%;
          padding-right: 0;
        }

        &__info {
          width: 100%;
        }

        &__data {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .case {
      &-description {

        &:after {
          display: none;
        }

        &__list {
          height: auto;
          margin-bottom: 50px;
        }
      }
    }

    .partners {
      &__item {
        height: auto;
        margin-bottom: 20px;
      }
    }

    .license {
      &__item {
        &-tx {
          width: calc(100% - 280px);
        }

        &-img {
          padding: 20px;

          flex: 0 0 280px;
          max-width: 280px;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .contacts {
      &__item {
        height: auto;
        margin-bottom: 30px;
      }
    }

    .operator {
      &__inner {
        margin-bottom: 50px;
      }
    }

    .tariffs {
      &-schema {
        &__holder {
          display: flex;
          flex-wrap: wrap;
        }

        &__inner {
          order: 2;
          margin-top: 50px;
        }

        &__text {
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
          order: 1;
        }
      }
    }

    .footer {
      &__left {
        display: block;
        justify-content: space-between;
      }

      &__menu {
        flex: 0 0 20%;
        padding-right: 0;
        margin-bottom: 0;

      }

      &__bottom {
        ul {
          margin-left: 0;
        }

        &-left {
          display: block;
        }
      }
    }

    .connect {
      overflow: hidden;
    }

    .purpose {
      padding-bottom: 50px;

      &__inner {
        .nav {
          &-item {
            width: 250px;
          }
        }

        .tab-content {
          width: 250px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {

    .header {
      &__tel {
        width: 24px;
        height: 24px;

        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;

        background-image: url("../images/svg/phone.svg");
        background-size: 24px 24px;
        background-repeat: no-repeat;

        overflow: hidden;
        color: transparent;
      }

      &__info {
        .button {
          right: 60px;
        }
      }
    }

    .button {
      display: inline-block;
      padding: 10px 23px;

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      border-radius: 5px;
      text-align: center;

      &.mob {
        display: block;
      }

      &-center {
        .button {
          width: 100%;
          margin: 0 0 15px;
        }

        &.mob {
          display: block;
        }
      }

      &-group {
        .button {
          margin-right: 15px;
        }
      }
    }

    h1 {
      margin-bottom: 20px;

      font-weight: 600;
      font-size: 34px;
      line-height: 110%;

      .policy & {
        font-size: 27px;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 110%;
    }

    h3 {
      font-weight: 600;
      font-size: 26px;
      line-height: 110%;
    }

    .breadcrumb {
      &-block {
        padding: 20px 0;
      }

      .breadcrumb-item {
        font-size: 12px;
        line-height: 16px;
        counter-increment: none;

        a {
          font-size: 12px;
          line-height: 16px;
        }

        & + .breadcrumb-item {
          &:before {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .title {
      &-main {
        margin-bottom: 30px;

        &__link {
          display: none;
        }
      }
    }

    .banner {
      &__slider {
        .progressBarContainer {
          bottom: 60px;

          &__title {
            bottom: -25px;

            font-weight: 300;
            font-size: 10px;
            line-height: 170%;
          }

          .progressBar {
            height: 2px;

            .inProgress {
              height: 2px;
              top: 17px!important;
            }
          }
        }

        &-img {
          display: none;
        }

        &-item {
          padding: 43px 0 108px;
        }

        &-categor {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 10px;
          line-height: 140%;
        }

        &-title {
          font-weight: 600;
          font-size: 31px;
          line-height: 100%;
        }

        &-text {
          p {
            margin-bottom: 30px;

            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
          }
        }

        &-img {
          display: none;
        }
      }

      &-main {
        h1, h2 {
          margin-bottom: 20px;
          font-size: 34px;
          line-height: 110%;
        }

        &__inner {
          padding: 50px 0;
        }

        &__categor {
          font-size: 12px;
          line-height: 16px;
        }

        &__text {
          p {
            margin-top: 0!important;

            font-size: 14px;
            line-height: 20px;
          }
        }

      }
    }

    .services {
      padding: 55px 0 40px;

      &-page {
        padding: 0 0 35px;
      }

      .col-lg-3 {
        margin-bottom: 20px;
      }

      &__item {
        padding: 18px 15px;
        min-height: 165px;
        border-radius: 5px;

        background-size: 70px 70px;
        background-position: 100% 75%;

        &-tag {
          padding: 5px 12px;

          font-size: 10px;
          line-height: 12px;

          border-radius: 0 5px 0 5px;
        }

        &-img {
          background-image: url("../images/svg/serv-icon12.svg")!important;
          background-position: 97% 75%;
          background-size: 70px 70px;

          .services__item-title, p {
            display: block;
            color: $black2;

            br {
              display: none;
            }
          }

          svg {
            display: none;
            width: 21px!important;
            height: 6px!important;

            &.mob {
              display: block!important;
            }

            use {
              color: $black2!important;
            }
          }
        }

        &-title {
          min-height: auto;

          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-weight: 300;
          font-size: 12px;
          line-height: 138%;
        }

        svg {
          bottom: 15px;
          left: 18px;
        }
      }
    }

    .about {
      padding: 55px 0 40px;

      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      &__text {
        padding-right: 0;

        h2 {
          margin-bottom: 20px;
        }

        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 170%;
        }
      }

      &__item {
        margin-bottom: 25px;

        &-title {
          margin-bottom: 7px;

          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
        }

        p {
          font-weight: 300;
          font-size: 12px;
          line-height: 138%;
        }

        .button {
          width: 100%;
          margin: 0;
        }
      }
    }

    .case {
      padding: 55px 0 35px;

      &-page {
        padding: 0 0 35px;
      }

      .col-lg-6 {
        margin-bottom: 18px;
      }

      &__item {
        height: auto;

        &-img {
          height: 130px;
          padding: 15px 10px;
        }

        &-sticker {
          &-item {
            margin-bottom: 8px;
          }

          &-title {
            margin-bottom: 0;

            font-size: 12px;
            line-height: 14px;

            img {
              width: 18px;
            }
          }

          &-tx {
            font-weight: 300;
            font-size: 12px;
            line-height: 138%;
          }
        }

        &-info {
          padding: 15px 11px 35px;

          p {
            font-weight: 300;
            font-size: 12px;
            line-height: 138%;
          }

          svg {
            bottom: 15px;
            left: 11px;
          }
        }

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__tabs {
        .nav {
          &-link {
            padding: 8px 25px;

            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      &-description {
        &__text {
          &-inner {
            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &__list {
          padding: 20px;

          &-item {
            margin-bottom: 20px;
          }

          &-tx {
            p {
              font-size: 14px;
              line-height: 20px;
            }
          }

          &-title {
            font-size: 18px;
            line-height: 28px;
          }

          &-img {
            flex: 0 0 80px;
            max-width: 80px;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    .connect {
      padding: 55px 0 35px;

      h2 {
        margin-bottom: 35px;
      }

      &__inner {
        display: block;
        margin-bottom: 0;
      }

      &__item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 55px;

        &:after {
          display: none;
        }

        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }

        &-img {
          width: 68px;
          height: auto;
          margin: auto;

          img {
            max-width: 100%;
          }
        }

        &-numb {
          margin: 24px 0 8px;
          width: 43px;
          height: 43px;

          font-weight: 600;
          font-size: 17px;
          line-height: 120%;
        }

        &-tx {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .news {
      padding: 55px 0 35px;

      &-page {
        padding: 0 0 35px;
      }

      .col-lg-4 {
        margin-bottom: 20px;
      }

      &-item {
        &__description {
          h2 {
            margin: 20px 0;

            font-size: 28px;
            line-height: 110%;
          }

          p {
            font-size: 14px;
            line-height: 20px;
          }

          ol {
            & > li {
              padding-left: 40px;

              font-size: 14px;
              line-height: 20px;

              &:before {
                width: 30px;
                height: 30px;

                font-size: 14px;
                line-height: 20px;
              }
            }
          }

          ul {
            li {
              font-size: 14px;
              line-height: 20px;
            }
          }

          table {
            tr {
              td,th {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }

      &__item {
        height: auto;
        padding: 25px 20px 65px;

        p {
          font-weight: 300;
          font-size: 12px;
          line-height: 138%;
        }

        svg {
          bottom: 15px;
          left: 20px;
        }

        &-data {
          margin-bottom: 20px;

          font-weight: 300;
          font-size: 12px;
          line-height: 170%;
        }

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          .news__item-img & {
            font-size: 25px;
            line-height: 30px;
          }
        }

        &-img {
          padding-bottom: 95px;

          .news-item__info & {
            height: 250px;
          }

          .button {
            left: 20px;
          }
        }
      }
    }

    .form {
      padding: 60px 0;

      .news-item__info & {
        padding: 20px;
      }

      &__inner {
        padding: 40px 20px;
      }

      &__top {
        margin-bottom: 27px;

        align-items: flex-start;

        h2 {
          margin-bottom: 13px;
        }

        img {
          width: 76px;
        }

        &-title {
          width: calc(100% - 90px);

          p {
            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
          }
        }
      }

      &__cell {
        margin-bottom: 25px;

        label {
          font-size: 14px!important;
          line-height: 17px!important;
        }

        input {
          font-size: 18px;
          line-height: 100%;
        }
      }

      &__bottom {
        display: block;
        margin-top: 25px;

        text-align: center;
      }

      &__tx {
        margin-left: 0;
        margin-top: 13px;

        font-weight: 300;
        font-size: 10px;
        line-height: 138%;
      }
    }

    .footer {
      &__left {
        display: block;
        padding-bottom: 48px;

        position: relative;
      }

      &__bottom {
        display: block;

        &-left {
          display: block;
        }
      }

      &__inner {
        padding-bottom: 25px;
      }

      &__menu {
        flex: 0 0 100%;
        margin-bottom: 0!important;

        br {
          display: none;
        }

        &-title {
          width: 100%;
          display: block;
          margin-bottom: 0;
          padding: 15px 0;

          position: relative;

          font-weight: 600;
          font-size: 14px;
          line-height: 17px;

          border-bottom: 1px solid rgba(196,196,196,0.1);
          pointer-events: none;

          &:after {
            content: '';
            width: 15px;
            height: 9px;

            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;

            background-image: url("../images/svg/arrow-ft.svg");
            background-size: 15px 9px;
            background-repeat: no-repeat;
            background-position: center right;
          }


          &.one {
            border: none;
            background-image: none;
            pointer-events: auto;

            position: absolute;
            bottom: 0;
            left: 0;

            &:after {
              display: none;
            }
          }
        }

        ul {
          display: none;
          padding: 3px 21px;

          li {
            margin-bottom: 11px;
          }
        }

        &-btn {
          display: none;
          padding-left: 21px;
          margin-top: 0;
        }

        &.open {
          margin-bottom: 25px;

          .footer__menu-title {
            pointer-events: none;
            border-color: transparent;
            border-bottom: 1px solid rgba(196, 196, 196, 0.1);

            &:after {
              transform: rotate(180deg);
            }
          }

          ul {
            display: block;
          }

          .footer__menu-btn {
            display: block;
          }
        }
      }

      &__contacts {
        margin-top: 35px;

        text-align: center;

        &-tel {
          font-size: 24px;
          line-height: 100%;
        }

        &-inf {
          font-size: 16px;
          line-height: 16px;
        }

        .button {
          display: block;
          width: 100%;

          background: $active;
          color: $white;
          border-color: $active;
        }

        &-soc {
          text-align: center;

          li {
            padding: 0!important;
            display: inline-flex!important;

            a {
              display: inline-flex!important;
              padding: 0!important;
              width: 40px!important;
              height: 40px!important;

              &:after {
                display: none!important;
              }
            }
          }
        }
      }

      &__bottom {
        display: block;
        padding-bottom: 45px;

        text-align: center;

        &-tx {
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 16px;
        }

        &-link {
          font-size: 12px;
          line-height: 16px;
        }

        &-left {
          ul {
            margin-bottom: 15px;
            display: block;

            li {
              display: block;
              margin-bottom: 5px;

              font-size: 12px;
              line-height: 16px;

              &:after {
                display: none!important;
              }
            }
          }
        }
      }

    }

    .pagination {
      &-bl {
        padding-bottom: 35px;
      }

      .page {
        &-item {
          margin: 0 2px;

          font-size: 14px!important;
          line-height: 20px!important;

          &.next,
          &.prev {
            width: 30px;
            height: 30px;
            min-height: inherit;
            margin: 0 10px;

            position: relative;

            .page-link {
              padding: 0;
            }


            .arrow {
              width: 30px;
              height: 30px;

              position: absolute;

              svg {
                width: 18px;
                height: 18px;
              }
            }

            .tt {
              display: none;
            }
          }

          &.next {
            .arrow {
              right: 0!important;
              top: 0!important;
            }
          }

          &.prev {
            .arrow {
              left: 0!important;
              top: 0!important;
            }
          }
        }

        &-link {
          width: 30px!important;
          height: 30px!important;
          font-size: 14px!important;
          line-height: 20px!important;
        }
      }
    }

    .terms  {
      ol  {
        & > li  {
          margin-bottom: 40px;

          & > ol  {
            margin-top: 0;

            & > li {
              font-size: 14px;
              line-height: 20px;

              &:before {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .https404 {
      &__inner {
        padding: 50px 0;

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__img {
        width: 300px;
        margin: auto;

        img {
          max-width: 100%;
        }
      }
    }

    .partners {
      padding-bottom: 35px;

      &__item {
        padding: 20px;

        &-tit {
          height: auto!important;
        }

        img {
          width: 80px;
        }

        p, ul li {
          font-size: 14px;
          line-height: 20px;
        }

        &-numb {
          top: 20px;

          font-size: 12px;
          line-height: 16px;

          .worth & {
            display: none;
          }
        }

        &-title {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .worth {
      padding: 50px 0;
    }

    .pagingInfo {
      width: 100%;
      text-align: center;
    }

    .license {
      padding: 55px 0 75px;

      &__slider {
        .slick-next {
          right: 0;
          left: 130px;
          margin: auto;
        }
        .slick-prev {
          right: 130px;
          left: 0;
          margin: auto;
        }
      }

      &__item {
        display: block!important;

        &-title {
          margin-bottom: 20px;

          font-weight: 600;
          font-size: 32px;
          line-height: 110%;
        }

        &-tx {
          width: 100%;
          padding-right: 0;
          margin-bottom: 30px;

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &-img {
          margin: 0 auto 50px;
        }
      }
    }

    .contacts {
      &__item {
        padding: 20px 20px 20px 77px;

        &-title {
          font-size: 18px;
          line-height: 28px;
        }

        &-img {
          img {
            width: 50px;
          }
        }
      }
    }

    .map {
      margin-bottom: 50px;
    }

    .requisites {
      padding-bottom: 70px;

      &__t {
        position: relative;
        padding-top: 50px;

        &:before {
          content: '';
          width: 70px;
          height: 50px;
          display: block;

          position: absolute;
          top: 0;
          right: 0;

          background-image: url("../images/svg/swipe.svg");
          background-repeat: no-repeat;
          background-size: 70px 50px;
        }
      }

      &__inner  {
        overflow: auto;

        table  {
          tr  {
            th {
              font-size: 18px;
              line-height: 28px;

              &:first-of-type {
                font-size: 25px;
                line-height: 35px;
              }
            }

            td {
              font-size: 14px;
              line-height: 24px;

              a {
                font-size: 14px;
                line-height: 24px;
              }
            }

            td, th {
              padding: 10px;
            }

            &.title {
              td {
                padding: 10px;

                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }

    .integration {
      &__inner {
        padding-bottom: 50px;
      }

      &__list {
        &-big {
          padding-bottom: 0;

          &:after {
            display: none;
          }
        }

        &-item {
          height: 200px;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    .operator {
      &__inner {

      }

      &__list {
        &-item {
          img {
            width: 80px;
          }
        }
      }

      &__sim {
        padding: 20px;

        &-img {
          flex: 0 0 100px;

          img {
            width: 100px;
          }
        }

        &-tx {
          width: calc(100% - 100px);
        }

        &-inp {
          input {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }

    .tariffs {
      &-text {
        padding: 55px 0;

        &.th {
          padding-top: 20px;

          .tariffs-text__inner {
            padding: 40px 0;
          }

          .tariffs-text__img  {
            height: auto;

            img {
              position: relative;
              left: 0;
            }
          }
        }

        &__inner {

          p {
            margin-bottom: 30px;

            font-size: 14px;
            line-height: 24px;
          }
        }

        &__img {
          margin-top: 30px;
          &.mob {
            display: none;
          }
        }
      }

      &-mailing {
        padding: 55px 0;

        &__t {
          position: relative;
          padding-top: 50px;

          &:before {
            content: '';
            width: 70px;
            height: 50px;
            display: block;

            position: absolute;
            top: 0;
            right: 0;

            background-image: url("../images/svg/swipe.svg");
            background-repeat: no-repeat;
            background-size: 70px 50px;
          }
        }

        &__inner {
          overflow: auto;

          position: relative;

          table {
            width: 768px;

            tr {
              th,td {
                padding: 10px;

                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }

      &-slider {
        padding-top: 20px;


        &__slider {
          &-img {
            width: 70px;
            height: 70px;

            img {
              max-width: 50px;
            }
          }

          &-title {
            font-size: 14px;
            line-height: 20px;
          }

          &-item {
            padding-top: 0;
            height: 160px;

          }

          &-img {
            &:after {
              display: none;
            }
          }
        }
      }

      &-advantages {
        padding: 55px 0;

        &__inner {
          padding-top: 20px;

          table {
            tr {
              display: block;

              td {
                display: block;

                padding: 15px 0;

                border: none;
                border-bottom: 1px solid $bord2!important;
              }
            }
          }

        }

        &__item {
          &-title {
            font-size: 18px;
            line-height: 28px;
          }

          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      &-schema {
        padding: 50px 0;
        overflow: hidden;

        &__text {
          h2 {
            margin-bottom: 30px;
          }

           p {
             margin-bottom: 30px;

             font-size: 14px;
             line-height: 24px;
           }

          ol {
            li {
              margin-bottom: 20px;

              font-size: 14px;
              line-height: 24px;

              &:before {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }

        &__inner {
          max-width: 280px;
          flex: 0 0 280px;

          &:before {
            content: '';
            width: 82px;
            height: 86px;

            right: -30px;
            bottom: 111px;

            background-size: 82px 86px;
          }

          &:after {
            width: 75px;
            height: 80px;

            top: 73px;

            background-size: 75px 80px;
          }

          &.mob {
            display: none;
          }

          &-time {
            padding: 4px 7px;

            font-size: 9px;
            line-height: 12px;

            &.tw {
              bottom: 72px;
              right: -15px;
            }

            &.on {
              top: 32%;
              left: 44%;
            }
          }

          &-tt {
            font-size: 12px;
            line-height: 14px;
          }

          &-item {
            margin-bottom: 35px!important;
            width: 280px;
            padding: 15px 15px 15px 60px;



            img {
              width: 30px;

              left: 10px;
            }

            &-title {
              font-size: 14px;
              line-height: 18px;
            }

            &-tx {
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
      }
    }

    .modal {
      &-body {
        padding: 20px 30px;

        &__title {
          font-size: 18px;
          line-height: 28px;
        }

        &__tx {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .purpose {
      padding: 55px 0;

      &-tw {
        padding: 55px 0;

        .tab-content {
          .purpose__content {
            display: none;
          }
        }

      }

      &__inner {
        .nav {
          column-count: 1;

          &-tabs {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 0;
          }

          &-item {
            flex: 0 0 100%;
            width: 100%;
            margin: 0;

            position: relative!important;
            right: 0!important;
            top: 0!important;

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              .nav-link {
                img {
                  left: 0;
                  right: inherit;
                }
              }
            }
          }

          &-link {
            margin: 0;
            padding: 20px 20px 20px 80px!important;

            img {
              width: 70px;

              top: 18px;
            }

            .tx {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .tab-content {
          width: 100%;
          height: auto;

          position: relative;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .tariffs-slider .slick-next {
      right: -12px;
    }
    .tariffs-slider .slick-left {
      left: -12px;
    }

    .tariffs-slider .slick-next:after,
    .tariffs-slider .slick-prev:after {
      width: 100px;
    }

    .tariffs-slider .slick-next:after {
      right: 0;
    }
    .tariffs-slider  {
      .slick-arrow {
        &:before {
          width: 45px;
          height: 45px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .purpose {
      &__inner {
        .tab-content {
          display: none;
        }
      }
    }

    .mob-d {
      display: block;
    }

    .mob-n {
      display: none;
    }
  }

  @media screen and (max-width: 575px) {
    .operator {
      &__sim {
        &-img {
          display: none;
        }

        &-tx {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }

























